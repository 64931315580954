import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AppStateService } from 'src/app/services/app-state.service';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {


  /* ----------------------------------------------------------------------------------
  ** Global variable
  ------------------------------------------------------------------------------------*/
  name;
  image;
  avatar$;
  userName$;
  /* ----------------------------------------------------------------------------------
  ** constructor
  ------------------------------------------------------------------------------------*/
  constructor(private appStateService: AppStateService,
    private inventFundsApiService: InventfundsApiService,
    public navCtrl: NavController,) {
  
    console.log("url =====  " +  this.appStateService.decryptedDataValue('imageURL'))
    if (this.appStateService.decryptedDataValue('imageURL') == null ) {
      this.image = 'assets/icon/header/avatar-empty.svg'
      this.appStateService.setAvatar('assets/icon/header/avatar-empty.svg')
      this.avatar$=this.appStateService.getAvatar();      
    } else {
      console.log( "............."+this.appStateService.decryptedDataValue('imageURL'))
      this.image = this.appStateService.decryptedDataValue('imageURL')
      this.appStateService.setAvatar(this.appStateService.decryptedDataValue('imageURL'))
      this.avatar$=this.appStateService.getAvatar();
    }
   this.getPersonDetails()
 
  }

  /* ----------------------------------------------------------------------------------
   ** ngOnInit
   ------------------------------------------------------------------------------------*/

  ngOnInit() { 
  
  }

  dashBoard(){
    this.navCtrl.navigateBack(['/dashboard']);
  }


  getPersonDetails() {
    var params = {
      collectionName: 'person',
      _id: this.appStateService.decryptedDataValue('personId'),
    };
    this.inventFundsApiService.retrieveMongoDBOne(params).then((res) => {
      console.log('res phone number : ' + JSON.stringify(res));
      this.appStateService.setName(res.response[0].name)
      this.userName$=this.appStateService.getName()
    }).catch(err => {
      console.error(err)
    })
  }

  goToIdea() {
    this.navCtrl.navigateBack(['/savedidea']);
  }
}
