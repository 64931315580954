import { CommonServiveService } from './../../common-servive.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { AppStateService } from 'src/app/services/app-state.service';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';
import { toastMessages } from 'src/utilities/toastMessage';
import { ChatPanelService } from './services/chat-panel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'; // Import Angular's date formatting
import { Message } from './interfaces/message';
import { SingleAttachmentComponent } from 'src/app/core/single-attachment/single-attachment.component';
import { AttachmentComponent } from 'src/app/core/attachment/attachment.component';
import { HttpClient } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Http } from '@capacitor-community/http';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { PusherService } from './services/pusher.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  /* ---------------------------------------------------------------------------------------------------
  ** Global Variables
  ** -------------------------------------------------------------------------------------------*/
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  @ViewChild('singleAttachment') singleAttachment: SingleAttachmentComponent;
  @ViewChild('attachment') attachment: AttachmentComponent;
  @ViewChildren('chatItem') chatItems: QueryList<ElementRef>;

  private resizeListener: () => void;

  private messagesStreamSubscription: Subscription;

  @Input() type: string;
  @Input() projectId: string;
  @Input() receivedContactId: boolean;
  @Output() projectTitle = new EventEmitter<any>();
  @Output() fixerTypeValue = new EventEmitter<any>();

  chatClick: boolean;
  spinner: boolean;
  chatLoading: boolean;
  retrievedProfileImages: any;
  chatMembers: any;
  memberIdArray: any;
  filteredChatMembers: any[];
  personType = this.appStateService.globalData.personType;
  queryStr: any = {};
  projectDetails: any;
  fixerType: string;
  selectedChatItem: any;
  selectedChatName: any;
  selectedChatImageUrl: any;
  selectedChatPersonId: any;
  showMessages: boolean = false;
  retrievedFunders: any[];
  funderMemberIdArray: any[];
  retrievedFunderProfileImages: any[];
  funderDetails: any[];
  dialogVisible: boolean;
  retrievedPhoneNumbers: any[];
  roleType: string = '';
  messageForm: FormGroup;
  chatForm: FormGroup;
  activeChannelId = null;

  selectedEmoji = '';
  showEmojiPicker: boolean = false;
  channelId: any;
  retrievedSelectedMessages: any;

  fileTypeImage = 'img';
  fileTypePdf = 'pdf';
  imagePathParams = { type: '' };
  attachmentUrl: any;
  dropdownOpen = false;
  newChannelIdCreated: boolean = false;
  selectedFileUrl: string | null = null;
  chatMembersIdArray: any[];
  selectedChatContactId: string = "";
  selectedChatUnreadCount: any;
  myPersonId: string;
  notificationCreated: boolean;
  selectedInviteStatus: any;
  connectionInitiator: any;
  selectedInboxId: any;
  skeletonArray = new Array(9).fill(1); // To Initialize Skeleton Template to 6 items
  screenRefreshed: boolean;
  inboxIdArray: any;
  showHeader: boolean = true;
  selectedChatPersonChannelId: any;
  showInput: boolean;
  justMovedItemId: string | null = null;
  windowWidth: number = window.innerWidth;
  receiverOnline: boolean;
  videoCallScreenType: 'caller' | 'receiver'; // Set this based on your logic
  private videoCallDeclineSubscription: Subscription;

  /* ---------------------------------------------------------------------------------------------------
  ** constructor() Starts
  ** -------------------------------------------------------------------------------------------*/
  constructor(
    public navCtrl: NavController,
    public CommonService: CommonServiveService,
    public appStateService: AppStateService,
    private inventFundsApiService: InventfundsApiService,
    private chatPanelService: ChatPanelService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public alertController: AlertController,
    private http: HttpClient,
    private pusherService: PusherService,
    private notificationService: NotificationServiceService,
  ) { }

  /* ---------------------------------------------------------------------------------------------------
  ** ngOnInit() Starts
  ** -------------------------------------------------------------------------------------------*/
  ngOnInit() {

    this.myPersonId = this.appStateService.globalData.personId;
    console.log("this.type", this.type);

    this.CommonService.getProjectRoleList();
    console.log("this.projectId", this.projectId);
    if (this.type === 'Project') {

      this.getProjectDetails();
    } else if (this.type === 'Inbox') {
      console.log("This is Inbox Screen");
      this.getPersonInbox();
    };

    this.chatForm = this.fb.group({
      chatMessage: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(5000),  // Adjust max length as needed
          Validators.pattern('^[\\S\\s]*$')  // Pattern to allow all characters including spaces
        ]),
      ],
    });

    console.log("this.appStateService.roleCode", this.appStateService.globalData.roleId);

    // for setting the personChannelId in appstateService file
    // this.CommonService.retrieveChannelId();

    // Register callbacks for all relevant notification types for Receiver Side
    this.notificationService.registerCallback('chatUpdate', this.updateReceiverChatView.bind(this));
    // Register callbacks for all relevant notification types for Sender Side
    this.notificationService.registerCallback('unreadUpdate', this.updateSenderUnreadCount.bind(this));
    this.notificationService.registerCallback('receiverChatOpened', this.updateReceiverOnline.bind(this));

    this.videoCallDeclineSubscription = this.CommonService.videoCallDecline$.subscribe(() => {
      this.callDeclinedAlert();
    });

    this.updateWindowWidth();
  };

  ionViewDidEnter() {

    console.log("this.type", this.type);
    console.log("hello");

    if (this.type === 'Inbox') {
      console.log("This is Inbox Screen");
      this.getPersonInbox();
    };


  }

  /* ---------------------------------------------------------------------------------------------------
  ** ngAfterViewInit() Starts
  ** -------------------------------------------------------------------------------------------*/
  ngAfterViewInit() {
    // Add resize listener after view initialization to ensure elements are available
    this.resizeListener = this.onResize.bind(this);
    // to check window resize and use attachment components respectively based on screen size
    window.addEventListener('resize', this.resizeListener);

  };

  /* ---------------------------------------------------------------------------------------------------
  ** ngOnDestroy() Starts
  ** -------------------------------------------------------------------------------------------*/
  ngOnDestroy() {
    // Listens of Window Screen Size Resize
    window.removeEventListener('resize', this.resizeListener);

    // Unsubscribe when the component is destroyed to prevent memory leaks, might have to change later when real time notifications to be
    if (this.messagesStreamSubscription) {
      this.messagesStreamSubscription.unsubscribe();
    };

    this.chatPanelService.unsubscribeChannelId();

    this.notifyReceiverChatOpened();

    if (this.videoCallDeclineSubscription) {
      this.videoCallDeclineSubscription.unsubscribe();
    }
  }

  onResize() {
    // Handle window resize if necessary
    this.updateWindowWidth();
  };

  updateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method Toggle Selected Chat View Starts
  ** -------------------------------------------------------------------------------------------*/
  toggleChat() {
    this.chatClick = !this.chatClick;
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Navigation Methods
  ** -------------------------------------------------------------------------------------------*/

  goToUserDashboard() {
    this.CommonService.setActiveTab('dashboard');
    this.navCtrl.navigateForward(['./dashboard/tabs/user-dashboard']);
  };

  goToHelpCenter() {
    let fromScreen = this.projectId ? 'projectMessages' : 'inbox';

    this.navCtrl.navigateForward(['./settings/help-center'],
      {
        queryParams: {
          id: this.projectId,
          fromScreen: fromScreen
        },
      }
    );
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Get Project from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getProjectDetails() {
    this.spinner = true;

    var params = {
      collectionName: 'projectDetails',
      _id: this.projectId,
    };
    this.inventFundsApiService
      .retrieveMongoDBOne(params)
      .then((res) => {
        this.projectDetails = res.response[0];

        console.log("this.projectDetails", this.projectDetails);
        console.log("this.projectDetails.personId", this.projectDetails.personId);

        this.emitProjectData();
        this.createQueryStr();

      })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Create Query String to Project Members Details Starts
  ** -------------------------------------------------------------------------------------------*/
  createQueryStr() {
    if (this.personType === 'Founder') {

      this.queryStr = {
        projectId: this.projectId,
        personIdHavingProject: { $ne: this.myPersonId },
        $or: [
          { inviteStatus: { $exists: false } }, // Include documents where inviteStatus does not exist
          { inviteStatus: "Accepted" }          // Include documents where inviteStatus is "Accepted"
        ]
      };

      this.getProjectPerson();

    } else if (this.personType === 'Funder') {

      this.queryStr = {
        projectId: this.projectId,
        personIdHavingProject: { $eq: this.projectDetails?.personId },
        founderPersonId: { $eq: this.projectDetails?.personId },
      };

      this.getProjectPerson();
    } else if (this.personType === 'Fixer') {
      this.getGeneralInfoDetails();
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Check whether a Fixer is a Company or Not Starts
  ** -------------------------------------------------------------------------------------------*/
  getGeneralInfoDetails() {
    var params = {
      collectionName: 'person',
      _id: this.myPersonId,
    };
    this.inventFundsApiService
      .retrieveMongoDBOne(params)
      .then((res) => {

        let retrievedPersonInfo = res.response[0];

        console.log("retrievedPersonInfo", retrievedPersonInfo);
        console.log("retrievedPersonInfo?.companyValue", retrievedPersonInfo?.companyValue);

        this.fixerType = retrievedPersonInfo?.companyValue === true ? "Company" : null;
        console.log("this.fixerType", this.fixerType);

        if (this.fixerType !== "Company") {
          // If the Logged In Fixer is not a Company, then this method is called to check whether the fixer is a Company Member or Individual
          this.getPersonTeams();
        } else {
          // If fixer is a Company, use this query to get all Company Team Members and Founder
          this.emitFixerType();
          this.queryStr = {
            projectId: this.projectId,
            $or: [
              { personIdHavingProject: { $eq: this.projectDetails?.personId } },
              {
                personIdHavingProject: { $eq: this.myPersonId },
                memberId: { $ne: "" }
              }
            ]
          };
          this.getProjectPerson();
        }

      })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Check whether a Fixer is a Company Member or Individual Starts
  ** -------------------------------------------------------------------------------------------*/
  getPersonTeams() {

    var params = {
      collectionName: 'personTeams',
      queryStr: { memberId: this.myPersonId },
    };
    this.inventFundsApiService
      .retrieveMongoDBAll(params)
      .then((res) => {
        let retrievedPersonTeams = res.response;

        console.log("Retrieved Teams", retrievedPersonTeams);

        this.fixerType = retrievedPersonTeams?.length > 0 ? "companyMember" : "individual";
        this.emitFixerType();

        console.log("this.fixerType", this.fixerType);

        // If fixer is an Individual, use this query to get only the Founder
        if (this.fixerType == "individual") {
          this.queryStr = {
            projectId: this.projectId,
            personIdHavingProject: { $eq: this.projectDetails?.personId },
            founderPersonId: { $eq: this.projectDetails?.personId },
          };
          this.getProjectPerson();
        } else if (this.fixerType == "companyMember") {

          let companyPersonId = retrievedPersonTeams[0]?.companyPersonId;
          // If the fixer is a companyMember, use this query to get all Company Team Members and Founder
          this.queryStr = {
            projectId: this.projectId,
            $or: [
              { personIdHavingProject: { $eq: this.projectDetails?.personId } },
              {
                personIdHavingProject: { $eq: companyPersonId },
                memberId: { $ne: this.myPersonId }
              }
            ]
          };
          this.getProjectPerson();
        }
      })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Get Project Members Details from DB Starts
 ** -------------------------------------------------------------------------------------------*/
  getProjectPerson() {

    var paramsValue = {
      collectionName: 'projectsPerson',
      queryStr: this.queryStr
    };

    this.inventFundsApiService.retrieveMongoDBAll(paramsValue).then((res) => {

      this.chatMembers = res.response;

      console.log('this.chatMembers Response', res.response);
      console.log('this.chatMembers', this.chatMembers);

      if (this.chatMembers && this.chatMembers.length > 0) {
        this.memberIdArray = this.chatMembers.map(
          (team) => team.memberId ? team.memberId : team.personIdHavingProject
        );

        console.log("Member Id Array", this.memberIdArray);

        this.getProfileImages();
      } else {
        // Make the spinner false if there are yet no project team members
        this.spinner = false;
      }

      // if (this.personType === 'Founder') {
      //   this.getFunders();
      // }

    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Get All the Funders from DB Starts
** -------------------------------------------------------------------------------------------*/
  getFunders() {
    this.retrievedFunders = []; // Clear the array before retrieving data
    this.funderMemberIdArray = []; // Clear the array before retrieving data

    const params = {
      collectionName: 'personInvestment',
      queryStr: {
        projectId: this.projectId,
        agreementSigned: true
      },
    };

    this.inventFundsApiService.retrieveMongoDBAll(params).then((res: any) => {
      this.retrievedFunders = res.response;


      if (this.retrievedFunders && this.retrievedFunders.length > 0) {

        console.log("this.retrievedFunders", this.retrievedFunders);

        this.funderMemberIdArray = this.retrievedFunders.filter(funder => funder.funderPersonId !== "").map(funder => funder.funderPersonId);

        console.log("Funder Member Id Array", this.funderMemberIdArray);

      };

      if (this.funderMemberIdArray.length > 0) {
        // Call only if any funders exist in the project
        this.getProfileImage();
      } else {
        // If there are currently no funders in the project then call this.getChatsContacts()
        this.getChatsContacts();
      }

    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Get Profile Images of all the Funders from DB Starts
   ** -------------------------------------------------------------------------------------------*/
  getProfileImage() {
    this.retrievedFunderProfileImages = [];

    const params = {
      collectionName: 'attachment',
      queryStr: { personId: { $in: this.funderMemberIdArray } },
    };

    this.inventFundsApiService.retrieveMongoDBAll(params).then((res) => {
      this.retrievedFunderProfileImages = res.response;

      console.log("Retrieved Profile Images", this.retrievedFunderProfileImages);
      this.retrievedFunders.forEach(person => {

        const profileImage = this.retrievedFunderProfileImages.find(image => image.personId === person.funderPersonId);
        if (profileImage) {
          person.imageUrl = profileImage.url;
        }
      });

      console.log("this.retrievedFunders", this.retrievedFunders);

      this.getFunderDetails();
    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Get Funder Details from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getFunderDetails() {
    this.funderDetails = [];

    const params = {
      collectionName: 'person',
      queryStr: {},// leave this empty when an array of personId is passed which must be converted to ObjectId in the API to get the Required Response
      objectCheck: true,
      arrayData: this.funderMemberIdArray,
      maskFields: ['phoneNumber']
    };

    this.inventFundsApiService.retrieveMongoDBAllWithMask(params).then((res) => {
      this.funderDetails = res.response;

      console.log("this.funderDetails", this.funderDetails);

      this.retrievedFunders.forEach(person => {

        console.log("person._id", person.personId);
        console.log("this.founderNames[0].personId", this.funderDetails[0]._id);

        const personName = this.funderDetails.find(item => item._id === person.funderPersonId);
        if (personName) {
          person.name = personName.name;
          person.number = personName.phoneNumber;
        }
      });

      // Add all items in this.retrievedFunders to this.chatMembers
      this.chatMembers = this.chatMembers.concat(this.retrievedFunders);
      this.filteredChatMembers = this.filteredChatMembers.concat(this.retrievedFunders);
      // Or you can use push with spread operator
      // this.chatMembers.push(...this.retrievedFunders);

      if (this.filteredChatMembers && this.filteredChatMembers.length > 0) {
        this.chatMembersIdArray = [];

        console.log("this.retrievedFunders", this.filteredChatMembers);

        this.chatMembersIdArray = this.filteredChatMembers.map(person => (person.funderPersonId || person.memberId || person.personIdHavingProject));

        console.log("chatMembersIdArray:", this.chatMembersIdArray);

        this.getChatsContacts();
      };

      // this.retrievedProjects Updated with Names for Each Funder if it exists
      console.log("this.retrievedProjects", this.retrievedFunders);
      console.log("this.filteredChatMembers", this.filteredChatMembers);
    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Get Profile Images for Person Team Members from DB Starts
** -------------------------------------------------------------------------------------------*/

  getProfileImages() {
    this.retrievedProfileImages = [];
    const params = {
      collectionName: 'attachment',
      queryStr: { personId: { $in: this.memberIdArray } },
    };

    this.inventFundsApiService.retrieveMongoDBAll(params).then((res) => {
      this.retrievedProfileImages = res.response;

      console.log("Retrieved Profile Images", this.retrievedProfileImages);

      // this block will run for Project Messages
      if (this.type === "Project") {
        this.chatMembers.forEach(person => {

          const profileImage = this.retrievedProfileImages.find(image => image.personId === (person.memberId ? person.memberId : person.personIdHavingProject));
          if (profileImage) {
            person.imageUrl = profileImage.url;
          }
        });
        // this block will run for inbox
      } else {
        this.chatMembers.forEach(person => {
          // If currently logged in personId is the connectionInitiator then pass the receiverId or else pass the senderId
          const profileImage = this.retrievedProfileImages.find(image => image.personId === (this.myPersonId == person.connectionInitiator ? person.receiverId : person.senderId));
          if (profileImage) {
            person.imageUrl = profileImage.url;
          }
        });

      }


      console.log("this.chatMembers", this.chatMembers);

      this.getPhoneNumbers();

    }).catch((err: any) => {
      console.error(err);
      this.spinner = false;
      var obj = toastMessages.retrieveErrMess;
      this.appStateService.genericToast(obj);
    });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Get Project Team Members Phone Numbers from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getPhoneNumbers() {
    this.retrievedPhoneNumbers = [];
    const params = {
      collectionName: 'person',
      queryStr: {},
      objectCheck: true,
      arrayData: this.memberIdArray,
      maskFields: ['phoneNumber'] // Array of fields to mask
      // maskFields: ['phoneNumber', 'ein'] // Array of fields to mask
      // maskFields: ['phoneNumber', 'email'] // Array of fields to mask
    };

    this.inventFundsApiService.retrieveMongoDBAllWithMask(params).then((res) => {
      this.retrievedPhoneNumbers = res.response;

      console.log("this.retrievedPhoneNumbers", this.retrievedPhoneNumbers);

      // this block will run for Project Messages
      if (this.type === "Project") {
        this.chatMembers.forEach(person => {
          const personPhoneNumber = this.retrievedPhoneNumbers.find(number => number._id === (person.memberId ? person.memberId : person.personIdHavingProject));
          if (personPhoneNumber) {
            person.number = personPhoneNumber.phoneNumber;
            // person.email = personPhoneNumber.email; // if you also want to display the masked email
          }
        });
        // this block will run for inbox
      } else {
        this.chatMembers.forEach(person => {
          // If currently logged in personId is the connectionInitiator then pass the receiverId or else pass the senderId
          const personPhoneNumber = this.retrievedPhoneNumbers.find(number => number._id === (this.myPersonId == person.connectionInitiator ? person.receiverId : person.senderId));
          if (personPhoneNumber) {
            person.number = personPhoneNumber.phoneNumber;
            person.name = personPhoneNumber.name;
          }
        });

      }

      this.filteredChatMembers = [...this.chatMembers]; // Initialize with all members

      this.chatMembersIdArray = this.filteredChatMembers.map(person => (person.funderPersonId || person.memberId || person.personIdHavingProject));

      // only get details for project chat for now, will be changed later
      // if (this.type === "Project") {
      // this.getChatsContacts();
      if (this.personType === 'Founder' && this.type === 'Project') {
        this.getFunders();
      } else {
        this.getChatsContacts();
      }
      // } else {
      // this.spinner = false;
      // }
    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Search People Starts
  ** -------------------------------------------------------------------------------------------*/
  searchPeople(event) {
    const query = event.target.value.toLowerCase();
    this.filteredChatMembers = this.chatMembers.filter(item => {
      const name = item.name ? item.name.toLowerCase() : '';
      const personName = item.personData && item.personData.name ? item.personData.name.toLowerCase() : '';
      return name.includes(query) || personName.includes(query);
    });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Project Title to Display in the Header Starts
  ** -------------------------------------------------------------------------------------------*/
  emitProjectData(): void {
    this.projectTitle.emit(this.projectDetails.projectTitle);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Project Title to Display in the Header Starts
  ** -------------------------------------------------------------------------------------------*/
  emitFixerType(): void {
    this.fixerTypeValue.emit(this.fixerType);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Initiate the Selected Chat Starts
  ** -------------------------------------------------------------------------------------------*/
  selectChatItem(item: any) {
    console.log("Messages", this);
    console.log("Selecting chat item:", item);

    // Common Variable setting for both Project and Inbox Screens
    if (this.showEmojiPicker) {
      this.toggleEmojiPicker();
    }
    this.chatLoading = true;
    this.chatForm.reset();
    this.retrievedSelectedMessages = [];
    console.log("Chat loading set to true, messages array cleared.");

    // For Inbox Chats only
    if (this.type === "Inbox") {
      this.setChatForInbox(item);
      // For Project Messages Chats only
    } else if (this.type === "Project") {
      this.setChatForProjectMessages(item);
    };


  };

  setChatForInbox(item) {
    this.selectedInviteStatus = item.inviteStatus;
    this.selectedInboxId = item._id;
    console.log("this.selectedInviteStatus", this.selectedInviteStatus);

    // To display the typing section for the connectionInitiator when receiever has accepted the connection request
    if (this.selectedInviteStatus === "Accepted") {
      this.chatLoading = false;
    }

    this.selectedChatItem = item;
    // If currently logged in personId is the connectionInitiator in personInbox Record then set item.receiverId as selectedChatPersonId else set item.senderId
    this.selectedChatPersonId = (this.myPersonId == item.connectionInitiator ? item.receiverId : item.senderId);
    this.connectionInitiator = item.connectionInitiator;
    this.selectedChatName = (item.name);
    this.selectedChatImageUrl = item.imageUrl;
    this.showInput = item.inviteStatus === 'Accepted' ? true : false;

    console.log("selectedChatName", this.selectedChatName);
    console.log("selectedChatImageUrl", this.selectedChatImageUrl);


    if (item.unreadCount) {

      console.log("item?.unreadCount", item?.unreadCount[(this.myPersonId == item.connectionInitiator ? item.senderId : item.receiverId)]);
      // Old Code
      // this.selectedChatUnreadCount = item?.unreadCount[(this.myPersonId == item.connectionInitiator ? item.senderId : item.receiverId)] || 0;
      this.selectedChatUnreadCount = item?.unreadCount;
    }

    console.log("this.selectedChatUnreadCount", this.selectedChatUnreadCount);
    console.log("item.unreadCount", item.unreadCount);


    this.channelId = item?.channelId;
    this.selectedChatContactId = item?.contactId;
    console.log("Selected channel ID:", this.channelId);
    console.log("Selected contact ID:", this.selectedChatContactId);

    this.getPersonChannelId(this.selectedChatPersonId);

    // To check and set the role type
    this.checkRoleType(this.myPersonId == item.connectionInitiator ? item.receiverRoleCode : item.senderRoleCode);

    // Initialize the chat panel service with the new channel ID
    this.chatPanelService.initialize(this.channelId);
    console.log("Initialized chat panel service with channel ID:", this.channelId);

    // Unsubscribe from previous messagesStream subscription
    if (this.messagesStreamSubscription) {
      this.messagesStreamSubscription.unsubscribe();
    }

    console.log("Subscribing to messagesStream.");
    // Subscribe to the message stream to handle new incoming messages
    this.messagesStreamSubscription = this.chatPanelService.messagesStream.subscribe(
      this.newMessageEventHandler.bind(this)
    );

    // Fetch the messages for the selected chat
    this.getMessages();

    if (item?.unreadCount?.[this?.selectedChatPersonId] > 0) {
      console.log("inside if item?.unreadCount");
      this.resetUnreadCount(this.selectedChatPersonId, this.selectedChatContactId, item?.unreadCount);
    }

    // Made false temporarily
    // this.chatLoading = false;
  }

  setChatForProjectMessages(item) {

    this.selectedChatItem = item;
    this.selectedChatPersonId = (item.memberId || item.personIdHavingProject || item.funderPersonId);
    this.selectedChatName = (item.name || item.personData.name);
    this.selectedChatImageUrl = item.imageUrl;
    this.showInput = true; // will be set to strue for all chats since there is no connection request for project members


    if (item.unreadCount) {
      console.log("item?.unreadCount", item?.unreadCount[item?.memberId || item?.personIdHavingProject || item?.funderPersonId]);
      // Old Code
      // this.selectedChatUnreadCount = item?.unreadCount[item?.memberId || item?.personIdHavingProject || item?.funderPersonId] || 0;
      this.selectedChatUnreadCount = item?.unreadCount;
    }

    this.channelId = item?.channelId;
    this.selectedChatContactId = item?.contactId;
    console.log("Selected channel ID:", this.channelId);
    console.log("Selected contact ID:", this.selectedChatContactId);

    this.getPersonChannelId(this.selectedChatPersonId);

    // To check and set the role type
    this.checkRoleType();

    // Initialize the chat panel service with the new channel ID
    this.chatPanelService.initialize(this.channelId);
    console.log("Initialized chat panel service with channel ID:", this.channelId);

    // Unsubscribe from previous messagesStream subscription
    if (this.messagesStreamSubscription) {
      this.messagesStreamSubscription.unsubscribe();
    }

    console.log("Subscribing to messagesStream.");
    // Subscribe to the message stream to handle new incoming messages
    this.messagesStreamSubscription = this.chatPanelService.messagesStream.subscribe(
      this.newMessageEventHandler.bind(this)
    );

    // Fetch the messages for the selected chat
    this.getMessages();

    if (item?.unreadCount?.[this?.selectedChatPersonId] > 0) {
      console.log("inside if item?.unreadCount");
      this.resetUnreadCount(this.selectedChatPersonId, this.selectedChatContactId, item?.unreadCount);
    }
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Toggle the UI for Selected Chat Starts
  ** -------------------------------------------------------------------------------------------*/
  isSelected(item: any): boolean {
    return this.selectedChatItem === item;
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method Toggle the Contact Info Dialog Starts
  ** -------------------------------------------------------------------------------------------*/
  showDialog() {
    this.dialogVisible = true;
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Set the RoleType Starts
  ** -------------------------------------------------------------------------------------------*/
  checkRoleType(roleCode?) {
    console.log("this.selectedChatItem", this.selectedChatItem);

    if (this.type === "Project") {
      if (this.selectedChatItem?.investedAmount) {
        this.roleType = 'Funder';
      } else if (this.selectedChatItem?.personIdHavingProject === this.selectedChatItem?.founderPersonId) {
        this.roleType = 'Founder';
      } else {
        this.roleType = "Fixer " + "(" + this.CommonService.roleMap[this.selectedChatItem?.projectRoleCode || ''] + ")";
        // this.roleType = this.selectedChatItem?.projectRoleCode || '';
      };
    } else {
      if (roleCode === '100') {
        this.roleType = 'Fixer';
      } else if (roleCode === '101') {
        this.roleType = 'Founder';
      } else if (roleCode === '103') {
        this.roleType = "Funder"
      } else {
        this.roleType = "Guest"
      }
    }

    console.log("this.roleType", this.roleType);

  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Send the Reply for Existing Channel or New Channel Starts
    ** -------------------------------------------------------------------------------------------*/

  // reply() {
  //   // const eventName = 'reply-notification';
  //   // const data = {
  //   //   notificationType: 'projectMessage',
  //   //   timestamp: new Date(),
  //   //   // Add any other relevant data here
  //   // };

  //   // // Notify the receiver (ensure channelId matches the receiver's subscription)
  //   // this.pusherService.notifyUser("private-crecientech-chat66a36162cd67818f50506029", eventName, data);
  //   this.sendChatNotification("projectMessage")
  // }


  reply() {
    if (this.isMessageValid() || this.attachmentUrl) {
      const createChannelParams = this.constructChannelParams();

      console.log('Creating channel with params:', createChannelParams);

      // --------------------------------------------- If channelId already exists -------------------------------------------------------
      if (this.channelId) {
        // console.log('Using existing channelId:', this.channelId);
        console.log('channelId Exists:', this.channelId);
        this.saveChatMessage(this.channelId)
          .then(() => {
            // this.chatForm.reset();
          })
          .catch(err => {
            console.error('saveChatMessage error:', err);
          });
      } else {
        // ------------------------------------------- Create a new channelId and save chat message --------------------------------------------
        this.createChannel(createChannelParams)
          .then(channelId => {
            console.log('Newly created channelId:', channelId);
            if (channelId) {
              this.newChannelIdCreated = true;
              this.channelId = channelId;

              // To Insert the ChannelId of that User after creating the channelId
              this.setChannelId(channelId);

              // this.filteredChatMembers.forEach(member => {
              //   const { memberId, personIdHavingProject, funderPersonId } = member;
              //   if ([memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
              //     member.channelId = channelId;
              //     console.log("member", member);
              //     console.log("channelId Inserted", channelId);

              //   }
              // })

              this.saveChatMessage(this.channelId)
                .then(() => {
                  // this.chatForm.reset();

                })
                .catch(err => {
                  console.error('saveChatMessage error:', err);
                });
            } else {
              console.error('channelId is not defined');
            }
          })
          .catch(err => {
            console.error('createChannel error:', err);
          });

      }
    } else {
      console.warn('Chat message is either invalid or empty');
    }
  };

  setChannelId(channelId) {
    if (this.type === 'Project') {
      this.filteredChatMembers.forEach(member => {
        const { memberId, personIdHavingProject, funderPersonId } = member;
        if ([memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
          member.channelId = channelId;
          console.log("member", member);
          console.log("channelId Inserted", channelId);

        }
      });

    } else {
      // this.filteredChatMembers.forEach(member => {
      //   const { memberId } = member;
      //   if (memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
      //     member.channelId = channelId;
      //     console.log("member", member);
      //     console.log("channelId Inserted", channelId);

      //   }
      // });

      this.filteredChatMembers.forEach(member => {
        // Determine which ID to compare based on your logic
        const targetId = (this.myPersonId === member.connectionInitiator) ? member.receiverId : member.senderId;

        // Check if the target ID matches the selectedChatPersonId
        if (targetId === this.selectedChatPersonId) {
          // Insert the channelId
          member.channelId = channelId;
          console.log("member", member);
          console.log("channelId Inserted", channelId);
        }
      });
    }
  }

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Get the contactId to the user when a new channel is Created Starts
    ** -------------------------------------------------------------------------------------------*/
  getContactId(chatData) {
    const params = {
      collectionName: 'contactsConnected',
      queryStr: {
        channelId: this.channelId,
      },
    };

    this.inventFundsApiService.retrieveMongoDBAll(params).then((res: any) => {
      // this.retrievedFunders = res.response;

      console.log("res.response[0]", res.response[0]);
      if (res.response[0]) {
        this.selectedChatContactId = res.response[0]._id;

        if (this.type === 'Project') {
          this.filteredChatMembers.forEach(member => {
            const { memberId, personIdHavingProject, funderPersonId } = member;
            if ([memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
              member.contactId = res.response[0]._id; // contactId is Inserted for the Chat Member
              // member.unreadCount = res.response[0].unreadCount; // unreadCount is Inserted for the Chat Member
              console.log("contactlId Inserted", res.response[0]._id);
              this.selectedChatUnreadCount = member.unreadCount;
            }
          });
        } else {
          this.filteredChatMembers.forEach(member => {
            // Determine which ID to compare based on your logic
            const targetId = (this.myPersonId === member.connectionInitiator) ? member.receiverId : member.senderId;

            // Check if the target ID matches the selectedChatPersonId
            if (targetId === this.selectedChatPersonId) {
              member.contactId = res.response[0]._id; // contactId is Inserted for the Chat Member
              // member.unreadCount = res.response[0].unreadCount; // unreadCount is Inserted for the Chat Member
              console.log("contactlId Inserted", res.response[0]._id);
              this.selectedChatUnreadCount = member.unreadCount;
            }
          });
        }

        // To update the Last message and Unread count in contactsConnected
        this.updateLastMessageAndUnread(chatData, true);

        // After sending the message, move the current chat top the index 0, in this case the channelId and contactId has been created and assigned and now the chat is moved to the top
        this.moveCurrentChatUpSender(chatData);

        //To Send Notification of the Message to Reciever after getting the created contactId
        if (!this.notificationCreated && this.type === 'Project') {
          this.createNotification("projectMessage", this.selectedChatPersonId);
        } else if (!this.notificationCreated && this.type === 'Inbox') {
          this.createNotification("inboxMessage", this.selectedChatPersonId);
        }
      };
      console.log("this.selectedChatUnreadCount", this.selectedChatUnreadCount);

      console.log("this.filteredChatMembers", this.filteredChatMembers);

    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to update the Last message details and unread count in contactsConnected Collection Starts
  ** -------------------------------------------------------------------------------------------*/
  updateLastMessageAndUnread(chatData, firstChat) {
    console.log("this.selectedChatContactId", this.selectedChatContactId);
    console.log("this.selectedChatContactId", this.selectedChatPersonId);
    console.log("chatData", chatData);
    console.log("chatData", firstChat);
    if (!firstChat) {
      console.log("this.selectedChatUnreadCount", this.selectedChatUnreadCount);
      console.log("this.selectedChatUnreadCount[this.myPersonId]", this.selectedChatUnreadCount[this.myPersonId]);
    };

    let unreadCountValue

    if (!this.receiverOnline) {
      unreadCountValue = {
        [this.myPersonId]: firstChat ? 1 : (this.selectedChatUnreadCount[this.myPersonId] + 1),
        // [this.myPersonId]: firstChat ? 1 : (this.selectedChatUnreadCount[this.myPersonId]) + 1,
        [this.selectedChatPersonId]: 0,
      };
    } else {
      unreadCountValue = {
        [this.myPersonId]: 0,
        // [this.myPersonId]: firstChat ? 1 : (this.selectedChatUnreadCount[this.myPersonId]) + 1,
        [this.selectedChatPersonId]: 0,
      };
    }

    var params = {
      collectionName: 'contactsConnected',
      pkId: this.selectedChatContactId,

      updateData: {
        unreadCount: unreadCountValue,
        lastMessageTime: chatData.createdDate,
        lastMessage: (chatData.chatMessage || (this.isPdf(this.attachmentUrl) ? 'PDF File' : 'Image'))
      },
    };

    this.inventFundsApiService
      .updateMongodbData(params)
      .then((res) => {
        console.log("res.response", res.response);
        console.log("res.response.value.unreadCount", res.response.value.unreadCount);
        if (res.response) {
          let retrievedUnreadCount = res.response.value.unreadCount;

          if (this.type === 'Project') {
            this.filteredChatMembers.forEach(member => {
              const { memberId, personIdHavingProject, funderPersonId } = member;
              if ([memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
                member.unreadCount = retrievedUnreadCount; // unreadCount is Inserted for the Chat Member
                this.selectedChatUnreadCount = retrievedUnreadCount;
                console.log("unreadCount Updated inside updateLastMessageAndUnread to:", retrievedUnreadCount);
                console.log("this.selectedChatUnreadCount", this.selectedChatUnreadCount);

              }
            });

          } else {
            this.filteredChatMembers.forEach(member => {
              // Determine which ID to compare based on your logic
              const targetId = (this.myPersonId === member.connectionInitiator) ? member.receiverId : member.senderId;

              // Check if the target ID matches the selectedChatPersonId
              if (targetId === this.selectedChatPersonId) {
                member.unreadCount = retrievedUnreadCount; // unreadCount is Inserted for the Chat Member
                this.selectedChatUnreadCount = retrievedUnreadCount;
                console.log("unreadCount Updated inside updateLastMessageAndUnread to:", retrievedUnreadCount);
                console.log("this.selectedChatUnreadCount", this.selectedChatUnreadCount);
              }
            });
          }

          // To Update the Chat View on the Receiver's Side
          this.sendChatUpdateToReceiver("chatUpdate", res.response.value, chatData);

        }
      })
      .catch((err) => {
        console.error(err);
        var obj = toastMessages.saveErrMsg;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Check if the Message Typed is Valid Starts
    ** -------------------------------------------------------------------------------------------*/
  isMessageValid(): boolean {
    return this.chatForm.controls['chatMessage'].touched && this.chatForm.valid && this.chatForm.controls['chatMessage'].value.trim().length !== 0;
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Create Params for Create a New Channel Starts
  ** -------------------------------------------------------------------------------------------*/
  constructChannelParams(): any {
    if (this.type === 'Project') {
      return {
        senderId: this.appStateService.decryptedDataValue('personId'),
        receiverId: this.selectedChatPersonId,
        projectId: this.projectId,
        roleId: this.appStateService.globalData.roleId,
        lastMessage: this.chatForm.get('chatMessage').value || (this.isPdf(this.attachmentUrl) ? 'PDF File' : 'Image')
      };

    } else {
      return {
        senderId: this.appStateService.decryptedDataValue('personId'),
        receiverId: this.selectedChatPersonId,
        inboxId: this.selectedInboxId,
        roleId: this.appStateService.globalData.roleId,
        lastMessage: this.chatForm.get('chatMessage').value || (this.isPdf(this.attachmentUrl) ? 'PDF File' : 'Image')
      };
    }
  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Create a New channelId and Save to DB Starts
    ** -------------------------------------------------------------------------------------------*/
  createChannel(params): Promise<string> {
    return this.chatPanelService.createChannel(params)
      .then(result => {
        console.log('createChannel result:', result);

        if (result.status === '200' || result.status === '201') {
          console.log('createChannel response data:', result.data);

          if (result.message === 'Duplicate channel!' && result.data.length > 0) {
            const channelId = result.data[0].channelId;
            const initialCount = result.data[0].count ? result.data[0].count + 1 : 1; // Increment the existing count
            return Promise.resolve(channelId);
          } else if (result.data.length > 0) {
            const channelId = result.data[0].channelId;
            return Promise.resolve(channelId);
          }
        }

        return Promise.reject('createChannel did not return expected status');
      });
  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Save Sent Chat to DB Starts
    ** -------------------------------------------------------------------------------------------*/
  saveChatMessage(channelId: string): Promise<void> {
    const params = {
      collectionName: 'chatMessages',
      updateData: {
        senderId: this.appStateService.decryptedDataValue('personId'),
        receiverId: this.selectedChatPersonId,
        chatMessage: this.chatForm.get('chatMessage').value,
        date: new Date().toISOString(),
        channelId: channelId,
        readStatus: 'unRead',
        attachmentUrl: this.attachmentUrl || "",
        createdDate: new Date().toISOString(),
        createdBy: this.appStateService.decryptedDataValue('personId'),
        updatedDate: new Date().toISOString(),
        updatedBy: this.appStateService.decryptedDataValue('personId'),
      },
    };

    console.log('Saving chat message with params:', params);

    const message = {
      // who: this.activeId, // from collabapp
      who: this.selectedChatPersonId,
      me: this.appStateService.decryptedDataValue('personId'),
      message: this.chatForm.get('chatMessage').value,
      attachmentUrl: this.attachmentUrl || "",
      time: new Date().toISOString(),
      channelId: channelId
    };

    return this.chatPanelService.createChatData(params)
      .then(result => {
        console.log('createChatData result:', result);
        if (!this.selectedChatContactId) {
          this.getContactId(result.data[0]);
        } else {
          // to update the last message details and unread count to contactsConnected Collection
          this.updateLastMessageAndUnread(result.data[0], false);

          // After sending the message, move the current chat top the index 0
          this.moveCurrentChatUpSender(result.data[0]);

          console.log("this.notificationCreated", this.notificationCreated);


          // To Send Notification of the Message to Reciever
          if (!this.notificationCreated && this.type === 'Project') {
            this.createNotification("projectMessage", this.selectedChatPersonId);
          } else if (!this.notificationCreated && this.type === 'Inbox') {
            this.createNotification("inboxMessage", this.selectedChatPersonId);
          }
        }
        // will be done only once when a new channel is created
        if (this.newChannelIdCreated) {
          this.chatPanelService.initialize(this.channelId);

        };

        // this.sendChatNotification();

        // To Update the Chat View on the Receiver's Side
        // this.sendChatUpdateToReceiver("chatUpdate", result.data[0]);


        //sends the message to the other user with same channelId
        this.newMessage(message);
        // this.newMessage2(message);
        this.chatForm.reset();


        // After uploading and sending the attachment, the attachmentUrl is made empty
        this.attachmentUrl = "";
        return Promise.resolve();
      });
  };


  /* ---------------------------------------------------------------------------------------------------
  ** Method to send the new message in channel for the other Recepient Starts
  ** -------------------------------------------------------------------------------------------*/
  newMessage(message: any): void {
    this.chatPanelService.send({
      text: message.message,
      user: message.me,
      id: message.me,
      who: message.who,
      time: message.time,
      channelId: message.channelId,
      attachmentUrl: message.attachmentUrl
    });
    // After the changes to be done which has to be done only once when a new channelId is created, this.newChannelIdCreated is made to false;
    this.newChannelIdCreated = false;

    console.log("message", message.message);
    console.log("message.me", message.me);
    console.log("message.who", message.who);
    console.log("message.time", message.time);
    console.log("message.channelId", message.channelId);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Get Select Chat Messages from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getMessages() {
    var params = {
      channelId: this.channelId
    };
    this.chatPanelService.getChatData(params)
      .then((chats) => {
        if (chats.data && chats.data.length > 0) {
          // console.log('chats: ', chats.data);
          // console.log('chats data : ' + JSON.stringify(chats));

          this.retrievedSelectedMessages = this.groupMessagesByDate(chats.data);
          console.log('this.retrievedSelectedMessages: ', this.retrievedSelectedMessages);

          this.cdr.detectChanges();  // Ensure the view is updated
          // if (this.retrievedSelectedMessages.length > 0) {
          this.scrollToBottom();
          // }

          // to update the read status for unRead Messages in chatMessages Collection
          this.updateMessagesStatus();

        };
        this.chatLoading = false;

      })
      .catch((err: any) => {
        console.error(err);
        this.chatLoading = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to update the read status for unRead Messages in chatMessages Collection Starts
  ** -------------------------------------------------------------------------------------------*/
  updateMessagesStatus() {
    this.chatPanelService
      .updateMessageStatus(
        this.selectedChatPersonId,
        this.appStateService.decryptedDataValue('personId')
      )
      .then((result) => {
        console.log("result", result);
      })
      .catch((err) => {
        console.error('err : ' + JSON.stringify(err));
      });
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Insert the Newly Sent Message to this.retrievedSelectedMessages Starts
  ** -------------------------------------------------------------------------------------------*/
  // Handles the receipt of new messages
  private newMessageEventHandler(event: Message): void {
    console.log('Handling new message event: ', event);

    // Add the new message to the retrieved messages array
    this.retrievedSelectedMessages.push({
      channelId: event.channelId,
      chatMessage: event.text,
      createdDate: event.time,
      date: event.time,
      attachmentUrl: event.attachmentUrl,
      senderId: event.user,
      receiverId: event.who,
      _id: event.id,
      readStatus: "unRead",
      isDate: false
    });
    console.log("Updated retrievedSelectedMessages: ", this.retrievedSelectedMessages);

    // Scroll to bottom if there are messages
    if (this.retrievedSelectedMessages.length > 0) {
      // To insert Today's date Group when message is initiated for the first time
      if (this.retrievedSelectedMessages.length <= 1) {
        this.retrievedSelectedMessages = this.groupMessagesByDate(this.retrievedSelectedMessages);
        console.log("Updated retrievedSelectedMessages with date: ", this.retrievedSelectedMessages);
      }
      this.cdr.detectChanges();
      // setTimeout(() => {
        this.scrollToBottom();
      // });
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Format the Date for Grouping Starts
  ** -------------------------------------------------------------------------------------------*/
  getFormattedDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return formatDate(date, 'MMMM d, yyyy', 'en-US'); // Customize the date format as needed
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Group Messages by Date Starts
  ** -------------------------------------------------------------------------------------------*/
  groupMessagesByDate(messages: any[]): any[] {
    const groupedMessages = [];
    let currentDate = '';
    const today = this.getFormattedDate(new Date().toISOString());

    messages.forEach(message => {
      const messageDate = this.getFormattedDate(message.createdDate);
      if (messageDate !== currentDate) {
        currentDate = messageDate;
        groupedMessages.push({ isDate: true, date: currentDate });
      }
      groupedMessages.push({ ...message, isDate: false });
    });

    // Check if the last date is different from today and add "today" if needed
    if (currentDate !== today) {
      groupedMessages.push({ isDate: true, date: today });
    }

    return groupedMessages;
  };



  /* ---------------------------------------------------------------------------------------------------
    ** Method to Scroll Down to the Bottom of the Chat Starts
    ** -------------------------------------------------------------------------------------------*/
  private scrollToBottom(): void {
    console.log("scrollToBottom() called");

    try {
      this.scrollContainer.nativeElement.scrollTop = this?.scrollContainer?.nativeElement?.scrollHeight;
    } catch (err) {
      // console.error('Could not scroll to bottom:', err);
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Bind the clicked Emoji to the Chat Input Starts
  ** -------------------------------------------------------------------------------------------*/
  select(event) {
    const currentMessage = this.chatForm.get('chatMessage')?.value || '';
    const newMessage = currentMessage + event.emoji.native;
    this.chatForm.get('chatMessage')?.setValue(newMessage);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Toggle Emoji Picker Starts
  ** -------------------------------------------------------------------------------------------*/
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.dropdownOpen = false;
  };


  /* ---------------------------------------------------------------------------------------------------
  ** Method to Upload Image to S3 Bucket for Mobile Starts
  ** -------------------------------------------------------------------------------------------*/
  async uploadImageMobile(url) {
    this.fileTypeImage = 'img';
    this.attachmentUrl = url;
    if (this.attachmentUrl) {
      this.attachmentUrl = url;
      console.log('imageUrl', this.attachmentUrl);
      this.dropdownOpen = false;
    }
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Upload Image to S3 Bucket for Web Starts
   ** -------------------------------------------------------------------------------------------*/
  async uploadImageWeb(url) {
    this.fileTypeImage = 'img';

    this.attachmentUrl = url;

    if (this.attachmentUrl) {
      this.attachmentUrl = url;
      console.log('imageUrl', this.attachmentUrl);
      this.dropdownOpen = false;
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Upload PDF to S3 Bucket for Mobile Starts
  ** -------------------------------------------------------------------------------------------*/
  async uploadPdfMobile(url) {
    this.fileTypePdf = 'pdf';
    this.attachmentUrl = url;
    if (this.attachmentUrl) {
      this.attachmentUrl = url;
      console.log('imageUrl', this.attachmentUrl);
      this.dropdownOpen = false;
    }
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Upload PDF to S3 Bucket for Web Starts
   ** -------------------------------------------------------------------------------------------*/
  async uploadPdfWeb(url) {
    this.fileTypeImage = 'pdf';

    this.attachmentUrl = url;

    if (this.attachmentUrl) {
      this.attachmentUrl = url;
      console.log('imageUrl', this.attachmentUrl);
      this.dropdownOpen = false;
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Display Delete Attachment Confirmation Alert Starts
  ** -------------------------------------------------------------------------------------------*/
  async deleteAttachmentAlert() {
    const confirm = await this.alertController.create({
      header: 'Confirm',
      message: 'Are you sure you want to delete the Attachment?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel',
        },
        {
          text: 'Delete',
          cssClass: 'confirm-delete',
          handler: () => {
            // Call the delete method here
            this.deleteAttachment();
          }
        }
      ]
    });
    await confirm.present();
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Delete Attachment from  S3 Bucket and DB Starts
  ** -------------------------------------------------------------------------------------------*/
  deleteAttachment() {
    var fileData = {
      imageArray: [{
        "awsS3FileLocation": (this.attachmentUrl)
      }],
    };

    this.inventFundsApiService
      .deleatAttachmentFile(fileData)
      .then((res) => {
        // this.removeLogoImg();
        console.log("res", res);
        this.attachmentUrl = "";

        var obj = toastMessages.attachmentDeletedMsg;
        this.appStateService.genericToast(obj);
      })
      .catch((err) => {
        console.log("Error", err)
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Toggle Attachment Custom Dropdown Starts
  ** -------------------------------------------------------------------------------------------*/
  toggleDropdown() {
    // Always toggle the dropdown
    this.dropdownOpen = !this.dropdownOpen;

    // Toggle the emoji picker if it is shown
    if (this.showEmojiPicker) {
      this.toggleEmojiPicker();
    }
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Check if the Current File is PDF or not Starts
  ** -------------------------------------------------------------------------------------------*/
  isPdf(url: string): boolean {
    return url.toLowerCase().endsWith('.pdf');
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Download the Selected Image or PDF Starts
  ** -------------------------------------------------------------------------------------------*/
  async downloadFile(url: string) {
    const platform = Capacitor.getPlatform();
    console.log("platform", platform);

    if (platform === 'ios' || platform === 'android') {
      // Handle file download on native platforms (iOS/Android)
      try {
        const response = await Http.request({
          method: 'GET',
          url: url,
          responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
        const reader = new FileReader();

        reader.onloadend = async () => {
          const base64data = reader.result as string;
          const fileName = url.split('/').pop() || 'download';
          const filePath = `downloaded_files/${fileName}`;

          await Filesystem.writeFile({
            path: filePath,
            data: base64data.split(',')[1], // Extract base64 data part
            directory: Directory.Documents,
            encoding: Encoding.UTF8
          });

          alert('File downloaded successfully');
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Download failed', error);
      }
    } else {
      // Handle file download on the web
      try {
        const response: any = await this.inventFundsApiService.retrieveSignedAttachment({ fileUrl: url });
        if (response && response.data) {
          this.handleFileDownload(response.data);
        } else {
          console.error('Failed to get signed URL.');
        }
      } catch (error) {
        console.error('Failed to fetch signed URL', error);
      }
    }
  };

  handleFileDownload(signedUrl: string) {
    // Redirect the current window to the signed URL
    window.location.href = signedUrl;
  };


  /* ---------------------------------------------------------------------------------------------------
    ** Method to Open and Close the File Viewer Starts
    ** -------------------------------------------------------------------------------------------*/
  openFileViewer(fileUrl: string) {
    this.selectedFileUrl = fileUrl;
    this.showHeader = false;
  };

  closeFileViewer() {
    this.selectedFileUrl = null;
    this.showHeader = true;
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to get the ChannelId and Last Message Details from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getChatsContacts() {
    let senderId = this.appStateService.decryptedDataValue('personId');
    let receiverId = this.selectedChatPersonId;

    console.log(this.filteredChatMembers);

    let queryStr;

    if (this.type === 'Project') {
      queryStr = {
        $or: [
          { senderId: senderId, receiverId: { $in: this.chatMembersIdArray }, projectId: this.projectId },
          { senderId: { $in: this.chatMembersIdArray }, receiverId: senderId, projectId: this.projectId }
        ]
      };
    } else {
      queryStr = {
        inboxId: { $in: this.inboxIdArray }
      };
    };

    const params = {
      collectionName: 'contactsConnected',
      queryStr: queryStr
    };

    this.inventFundsApiService
      .retrieveMongoDBAll(params)
      .then((res) => {
        console.log("res.response", res.response);
        console.log("res.response[0].channelId", res.response[0]?.channelId);
        console.log("this.filteredChatMembers before", this.filteredChatMembers);

        let contactsDetails = res.response;
        console.log("contactsDetails", contactsDetails);

        if (this.type === 'Project') {
          this.updateProjectContacts(contactsDetails);
        } else {
          this.updateInboxContacts(contactsDetails);
          console.log("this.type is:", this.type);
        }

        console.table(this.filteredChatMembers);

        // Sort this.filteredChatMembers based on lastMessageTime using the new method
        this.filteredChatMembers = this.sortChatMembersByLastMessageTime(this.filteredChatMembers);

        // Now this.filteredChatMembers is sorted based on lastMessageTime
        console.log(this.filteredChatMembers);

        if (this.type === "Project") {
          this.filteredChatMembers.forEach(message => {
            message.formattedLastMessageTime = this.getFormattedLastMessageTime(message?.lastMessageTime);
          });
        } else if (this.type === "Inbox") {
          this.filteredChatMembers.forEach(message => {
            message.formattedLastMessageTime = this.getFormattedLastMessageTime(message?.lastMessageTime || message?.createdAt);
          });
        }

        console.log(this.filteredChatMembers);

        // receivedContactId is obtained only if a notification is clicked and then navigated to projectMessages Screen
        if (this.receivedContactId) {
          console.log("this.receivedContactId", this.receivedContactId);

          // After loading all the contacts, this is used take to trigger the click in html of the respective user once to load the chat messages
          this.chatItems.changes.pipe(take(1)).subscribe(() => {
            this.triggerClickOnReceivedContact();
          });

        };

        this.spinner = false;


      })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to sort filteredChatMembers based on lastMessageTime Starts
  ** -------------------------------------------------------------------------------------------*/
  // Old Method
  // sortChatMembersByLastMessageTime(members: any[]): any[] {
  //   return members.sort((a, b) => {
  //     // Check if a and b have lastMessageTime defined
  //     if (a.lastMessageTime && b.lastMessageTime) {
  //       const dateA = new Date(a.lastMessageTime);
  //       const dateB = new Date(b.lastMessageTime);
  //       return dateB.getTime() - dateA.getTime(); // Descending order (latest first)
  //     } else if (a.lastMessageTime) {
  //       return -1; // a has lastMessageTime but b doesn't, a comes first
  //     } else if (b.lastMessageTime) {
  //       return 1; // b has lastMessageTime but a doesn't, b comes first
  //     } else {
  //       return 0; // Both do not have lastMessageTime, maintain current order
  //     }
  //   });
  // };

  // New Method
  sortChatMembersByLastMessageTime(members: any[]): any[] {
    return members.sort((a, b) => {
      // Determine the time to use for sorting for 'a'
      const timeA = a.lastMessageTime ? new Date(a.lastMessageTime).getTime() : new Date(a.createdAt).getTime();

      // Determine the time to use for sorting for 'b'
      const timeB = b.lastMessageTime ? new Date(b.lastMessageTime).getTime() : new Date(b.createdAt).getTime();

      // Sort in descending order (latest first)
      return timeB - timeA;
    });
  }


  /* ---------------------------------------------------------------------------------------------------
  ** Method to Update Project Contacts Starts
  ** -------------------------------------------------------------------------------------------*/
  updateProjectContacts(contactsDetails) {
    this.filteredChatMembers.forEach(person => {
      const contacts = contactsDetails.find(item => {
        let personIdHavingProjectToCheck = person.personIdHavingProject;

        if (person.memberId) {
          // If memberId exists, prioritize checking memberId for personIdHavingProject
          personIdHavingProjectToCheck = person.memberId;
        }

        // Check if senderId or receiverId matches any of the person identifiers
        const senderReceiverMatch =
          (item.senderId === person.funderPersonId ||
            item.senderId === person.memberId ||
            item.senderId === personIdHavingProjectToCheck ||
            item.receiverId === person.funderPersonId ||
            item.receiverId === person.memberId ||
            item.receiverId === personIdHavingProjectToCheck);

        // Check if channelInitiator matches any of the person identifiers or global personId
        const channelInitiatorMatch =
          (item.channelInitiator === this.myPersonId ||
            item.channelInitiator === person.funderPersonId ||
            item.channelInitiator === person.memberId ||
            item.channelInitiator === personIdHavingProjectToCheck);

        console.log("item.channelInitiator", item.channelInitiator);
        console.log("person.funderPersonId", person.funderPersonId);
        console.log("item.senderId", item.senderId);
        console.log("item.receiverId", item.receiverId);
        console.log("==============================================");
        console.log("senderReceiverMatch", senderReceiverMatch);
        console.log("channelInitiatorMatch", channelInitiatorMatch);

        // Ensure both conditions are met
        return senderReceiverMatch && channelInitiatorMatch;
      });

      if (contacts) {
        person.channelId = contacts.channelId;
        person.contactId = contacts._id; // to insert the contacts id which can be used to update the last message to contactsConnected Collection
        person.lastMessage = contacts.lastMessage;
        person.lastMessageTime = contacts.lastMessageTime;
        person.unreadCount = contacts.unreadCount;
      }
    });
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Update Inbox Contacts Starts
  ** -------------------------------------------------------------------------------------------*/
  updateInboxContacts(contactsDetails) {
    console.log("this.type is:", this.type);
    // Iterate over filteredChatMembers and update matching members
    this.filteredChatMembers.forEach(member => {
      contactsDetails.forEach(contact => {
        // Check if the member matches the contact based on the ID field
        if (member._id === contact.inboxId) {
          // Update the member with contact details
          member.channelId = contact.channelId;
          member.contactId = contact._id; // to insert the contacts id which can be used to update the last message to contactsConnected Collection
          member.lastMessage = contact.lastMessage;
          member.lastMessageTime = contact.lastMessageTime;
          member.unreadCount = contact.unreadCount;
        }
      });
    });
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Trigger the Click in HTML of the Chat Notification Starts
  ** -------------------------------------------------------------------------------------------*/
  triggerClickOnReceivedContact() {
    const chatItem = this.chatItems.find(item => item.nativeElement.getAttribute('data-contact-id') === this.receivedContactId);
    console.log("chatItem", chatItem);

    if (chatItem) {
      chatItem.nativeElement.click();
    };

  }

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Format and Display the Last Chat Time in Chat Members List Starts
 ** -------------------------------------------------------------------------------------------*/
  getFormattedLastMessageTime(dateString: string): string {
    if (!dateString) {
      return ''; // Handle if dateString is not provided
    }

    const date = new Date(dateString);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      // Display today's time
      return formatDate(date, 'h:mm a', 'en-US');
    } else if (date.toDateString() === yesterday.toDateString()) {
      // Display yesterday
      return 'Yesterday';
    } else {
      // Display date in the format: 7/7/24 (example: 7th July 2024)
      return formatDate(date, 'M/d/yy', 'en-US');
    }
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Move the Current Sent Chat to Top for Sender Starts
 ** -------------------------------------------------------------------------------------------*/
  moveCurrentChatUpSender(chatData) {
    console.log("chatData", chatData);
    // console.log("this.filteredChatMembers", this.filteredChatMembers);
    console.log("this.selectedChatContactId", this.selectedChatContactId);

    const index = this.filteredChatMembers.findIndex(
      member => member.contactId === this.selectedChatContactId
    );

    if (index > -1) {
      const [item] = this.filteredChatMembers.splice(index, 1); // Splice removes the item from it's current index and retruns it
      this.filteredChatMembers.unshift(item); // Moves the Chat Item to the Top of the Array
      item.lastMessage = (chatData.chatMessage || (this.isPdf(this.attachmentUrl) ? 'PDF File' : 'Image')); // Insert the latest message sent in the item
      item.lastMessageTime = chatData.createdDate; // Insert the latest lastMessageTime sent in the item
      item.formattedLastMessageTime = this.getFormattedLastMessageTime(chatData.createdDate); // Insert the latest formattedLastMessageTime sent in the item
      console.log("item.lastMessage", item.lastMessage);
      console.log("item.lastMessageTime", item.lastMessageTime);
      console.log("formattedLastMessageTime", item.formattedLastMessageTime);

    }

    console.log("Updated filteredChatMembers", this.filteredChatMembers);
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Reset the unreadCount for the Selected Chat in DB Starts
   ** -------------------------------------------------------------------------------------------*/
  resetUnreadCount(selectedChatPersonId, selectedChatContactId, unreadCount) {
    console.log("unreadCount", unreadCount);

    var params = {
      collectionName: 'contactsConnected',
      pkId: selectedChatContactId,

      updateData: {
        unreadCount: {
          [this.myPersonId]: unreadCount[this.myPersonId],
          [selectedChatPersonId]: 0,
        },
      },
    };

    this.inventFundsApiService
      .updateMongodbData(params)
      .then((res) => {
        console.log("res.response", res.response);

        if (this.type === 'Project') {
          this.filteredChatMembers.forEach(member => {
            const { memberId, personIdHavingProject, funderPersonId } = member;
            if ([memberId, personIdHavingProject, funderPersonId].includes(this.selectedChatPersonId)) {
              member.unreadCount[selectedChatPersonId] = 0;
              console.log("member", member);
              // console.log("channelId Inserted", channelId);

            }
          });
        } else {
          this.filteredChatMembers.forEach(member => {
            // Determine which ID to compare based on your logic
            const targetId = (this.myPersonId === member.connectionInitiator) ? member.receiverId : member.senderId;

            // Check if the target ID matches the selectedChatPersonId
            if (targetId === this.selectedChatPersonId) {
              member.unreadCount[selectedChatPersonId] = 0;
              console.log("member", member);
              // console.log("channelId Inserted", channelId);
            }
          });
        }

      })
      .catch((err) => {
        console.error(err);
        var obj = toastMessages.saveErrMsg;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Create and Save Notification in DB Starts
   ** -------------------------------------------------------------------------------------------*/
  createNotification(notificationType, selectedPersonId?) {
    console.log("createNotification Called");
    console.log("this.selectedChatContactId", this.selectedChatContactId);

    let updateData: Record<string, any> = {
      receiverId: selectedPersonId ? selectedPersonId : "",
      senderId: this.appStateService.globalData.personId,
      senderRoleCode: this.appStateService.globalData.roleCode,
      createdAt: moment().format(),
      updatedAt: moment().format(),
      readStatus: "unRead",
      notificationType: notificationType,
      contactId: this.selectedChatContactId
    };

    if (notificationType === 'projectMessage') {
      updateData.projectId = this.projectId;
      updateData.personIdHavingProject = selectedPersonId ? selectedPersonId : "";
      updateData.projectTitle = this.projectDetails['projectTitle'];
    } else {
      updateData.inboxId = this.selectedInboxId;
    }


    var params = {
      collectionName: 'personNotification',
      updateData: updateData,
      // Old updateData Params
      // updateData: {
      //   projectId: this.projectId,
      //   personIdHavingProject: selectedPersonId ? selectedPersonId : "",
      //   // founderPersonId: this.appStateService.globalData.personId, // not required
      //   receiverId: selectedPersonId ? selectedPersonId : "",
      //   senderId: this.appStateService.globalData.personId,
      //   senderRoleCode: this.appStateService.globalData.roleCode,
      //   projectTitle: this.projectDetails['projectTitle'],
      //   createdAt: moment().format(),
      //   readStatus: "unRead",
      //   notificationType: notificationType,
      //   contactId: this.selectedChatContactId
      // },
    };
    this.inventFundsApiService
      .createMongodbData(params)
      .then((res) => {

        console.log("res.response", res.response);
        if (res.response) {
          this.notificationCreated = true; // will be toggled to true as soon as 1 message is sent, creates notification only once even if multiple messages are sent
          this.sendChatNotification(notificationType);
        };

      })
      .catch((err: any) => {
        console.error(err);
        var obj = toastMessages.saveErrMsg;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Get All Chat Members for Inbox Screen from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  getPersonInbox() {
    this.spinner = true;

    var paramsValue = {
      collectionName: 'personInbox',
      queryStr: {
        $or: [
          { receiverId: { $eq: this.appStateService.globalData.personId } },
          {
            connectionInitiator: { $eq: this.appStateService.globalData.personId },
          }
        ]
      }
    };

    this.inventFundsApiService.retrieveMongoDBAll(paramsValue).then((res) => {

      this.chatMembers = res.response;

      console.log('this.chatMembers Response', res.response);
      console.log('this.chatMembers', this.chatMembers);

      if ((this.chatMembers && this.chatMembers.length > 0) || this.screenRefreshed) {
        // Extract _id values from chatMembers
        this.inboxIdArray = this.chatMembers.map((item) => item._id);

        // If currently logged in personId is the connectionInitiator then pass the receiverId or else pass the senderId
        this.memberIdArray = this.chatMembers?.map(
          (item) => (this.myPersonId == item.connectionInitiator ? item.receiverId : item.senderId));

        if (this.chatMembers.length > 0) {
          this.filteredChatMembers = [];
        }

        console.log("Member Id Array", this.memberIdArray);
        console.log("Inbox Id Array", this.inboxIdArray);
        // setTimeout(() => {
        this.getProfileImages();
        // }, 3000);
      } else {
        // Initialize the filteredChatMembers array again incase the user declines the request and there are no people to chat :)
        this.filteredChatMembers = [];
        this.spinner = false;


      }

    })
      .catch((err: any) => {
        console.error(err);
        this.spinner = false;
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Accept Connection Request for Receiever and Update Invite Status to DB Starts
  ** -------------------------------------------------------------------------------------------*/
  acceptConnectionRequest() {
    console.log("this.selectedInboxId", this.selectedInboxId);

    var params = {
      collectionName: 'personInbox',
      pkId: this.selectedInboxId,

      updateData: {
        inviteStatus: "Accepted"
      },
    };

    this.inventFundsApiService
      .updateMongodbData(params)
      .then((res) => {
        console.log("res.response", res.response);

        // Iterate over the filteredChatMembers array and update the inviteStatus for the matching member
        this.filteredChatMembers = this.filteredChatMembers.map((member) => {
          if (member._id === this.selectedInboxId) {
            return {
              ...member,
              inviteStatus: "Accepted"
            };
          }
          return member;
        });

        this.selectedInviteStatus = 'Accepted';
        this.chatLoading = false;
        this.showInput = true;
        // this.type = 'Inbox';

        var obj = toastMessages.acceptConnectReqMsg;
        this.appStateService.genericToast(obj);

      })
      .catch((err) => {
        console.error(err);
        var obj = toastMessages.updateErrMess;
        this.appStateService.genericToast(obj);
      });

  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Decline Connection Request for Receiever and and Delete record from DB Starts
  ** -------------------------------------------------------------------------------------------*/
  declineConnectionRequest() {
    console.log("this.selectedInboxId", this.selectedInboxId);

    var params = {
      collectionName: 'personInbox',
      pkId: this.selectedInboxId,

    };

    this.inventFundsApiService
      .deleteMongodbData(params)
      .then((res) => {
        console.log("res.response", res.response);

        // Remove the record from the local array after getting response
        this.filteredChatMembers = this.filteredChatMembers.filter(
          (member) => member._id !== this.selectedInboxId
        );

        // Empty all the selected chat person details after removing the record from DB
        this.selectedChatItem = '';
        this.selectedChatPersonId = '';
        this.connectionInitiator = '';
        this.selectedChatName = '';
        this.selectedChatImageUrl = '';


        var obj = toastMessages.declineConnectReqMsg;
        this.appStateService.genericToast(obj);

      })
      .catch((err) => {
        console.error(err);
        var obj = toastMessages.deleteErrMess;
        this.appStateService.genericToast(obj);
      });

  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Handle Pull to Refresh Starts
 ** -------------------------------------------------------------------------------------------*/
  handleRefresh(event) {
    this.screenRefreshed = true;
    // this.toggleChat();
    console.log("chatClick", this.chatClick);

    setTimeout(() => {
      // Any calls to load data go here
      event.target.complete();
      // this.selectedChatName = "";
      // this.selectedChatItem = "";
      // Check if the screen size is above 991px and if chatClick is false
      if (this.windowWidth > 991) {
        this.selectedChatName = "";
        this.selectedChatItem = "";
      }
      if (this.type === 'Project') {

        this.getProjectDetails();
      } else if (this.type === 'Inbox') {
        console.log("This is Inbox Screen");
        this.getPersonInbox();
      };
      console.log("getPersonInbox() called");

    }, 1000);
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Get personChannelId from DB Starts
   ** -------------------------------------------------------------------------------------------*/
  getPersonChannelId(selectedChatPersonId) {
    console.log("retrieveChannelId Called in service file");

    var params = {
      collectionName: 'personChannel',
      queryStr: {
        personId: selectedChatPersonId
      },
      fetchSingle: true // Toggle this to true when you want to fetch only 1 record using retrieveMongoDBAll
    };

    return this.inventFundsApiService.retrieveMongoDBAll(params)
      .then((res) => {
        console.log("res.response", res.response);
        if (res.response.length > 0) {
          this.selectedChatPersonChannelId = res.response[0]._id;
          console.log("this.selectedChatPersonChannelId", this.selectedChatPersonChannelId);

          // To update the unreadCount to the Sender after opening the chat
          this.notifySenderUnreadCount("unreadUpdate");
        } else {
          this.selectedChatPersonChannelId = "";
        }
      })
      .catch((err: any) => {
        console.error(err);
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
        // Return a rejected promise
        return Promise.reject(err);
      });
  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Send Notification to Receiver using personChannelId in Real Time Starts
    ** -------------------------------------------------------------------------------------------*/
  sendChatNotification(notificationType: string) {
    const eventName = 'reply-notification';
    const data = {
      notificationType: notificationType,
      timestamp: new Date(),
      // Add any other relevant data here
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.selectedChatPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);
    // old code
    // this.pusherService.notifyUser("private-crecientech-chat66a36162cd67818f50506029", eventName, data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
    ** Method to Send Notification to Receiver using personChannelId in Real Time to Update Chat View Starts
    ** -------------------------------------------------------------------------------------------*/
  sendChatUpdateToReceiver(notificationType: string, contactsData?, chatData?) {
    const eventName = 'reply-notification';
    const data = {
      notificationType: notificationType,
      timestamp: new Date(),
      contactsData: contactsData,
      chatData: chatData
      // Add any other relevant data here
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.selectedChatPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);
    // old code
    // this.pusherService.notifyUser("private-crecientech-chat66a36162cd67818f50506029", eventName, data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Receiver Chat View Starts
  ** -------------------------------------------------------------------------------------------*/
  updateReceiverChatView(data) {
    let chatData = data;
    console.log('Chat Update received in Messages Component at Receivers Side:', data);
    if (this.type === 'Project') {
      console.log("This is Project Messages Screen");
      this.moveCurrentChatUpReceiver(chatData);
      // this.getProjectDetails();
    } else if (this.type === 'Inbox') {
      console.log("This is Inbox Screen");
      this.moveCurrentChatUpReceiver(chatData);
      // this.getPersonInbox();
    };
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Move the Current Sent Chat to Top for Receiver Starts
 ** -------------------------------------------------------------------------------------------*/

  // Old Method
  // moveCurrentChatUpReceiver(chatData) {
  //   console.log("chatData", chatData);
  //   let receivedChatData = chatData.chatData;
  //   let receivedContactsData = chatData.contactsData;
  //   console.log("this.filteredChatMembers", this.filteredChatMembers);
  //   console.log("this.selectedChatContactId", this.selectedChatContactId);

  //   const index = this.filteredChatMembers.findIndex(
  //     member => member.channelId === receivedChatData.channelId
  //   );

  //   console.log("index", index);


  //   if (index > -1) {
  //     const [item] = this.filteredChatMembers.splice(index, 1); // Splice removes the item from it's current index and retruns it
  //     this.filteredChatMembers.unshift(item); // Moves the Chat Item to the Top of the Array
  //     item.lastMessage = (receivedChatData.chatMessage || (this.isPdf(receivedChatData.attachmentUrl) ? 'PDF File' : 'Image')); // Insert the latest message sent in the item
  //     item.lastMessageTime = receivedChatData.createdDate; // Insert the latest lastMessageTime sent in the item
  //     item.formattedLastMessageTime = this.getFormattedLastMessageTime(receivedChatData.createdDate); // Insert the latest formattedLastMessageTime sent in the item

  //     console.log("this.channelId", this.channelId);
  //     // Update unreadCount only if the respective sender's chat is not opened by the receiver
  //     if (this.channelId !== receivedChatData.channelId) {
  //       item.unreadCount = receivedContactsData.unreadCount; // Insert the latest lastMessageTime sent in the item
  //     };

  //     console.log("item.lastMessage", item.lastMessage);
  //     console.log("item.lastMessageTime", item.lastMessageTime);
  //     console.log("formattedLastMessageTime", item.formattedLastMessageTime);

  //     // Set justMovedItemId to trigger the animation at the Receiver's Side
  //     this.justMovedItemId = item.channelId;

  //     // Reset justMovedItemId after the animation duration (e.g., 300ms)
  //     setTimeout(() => {
  //       this.justMovedItemId = null;
  //     }, 300);

  //   } else {
  //     let index: any;
  //     if (this.type === 'Project') {
  //       index = this.filteredChatMembers.findIndex(
  //         member => (member.projectId === receivedContactsData.projectId) && (member.personIdHavingProject === receivedChatData.senderId)
  //       );
  //     } else if (this.type === 'Inbox') {
  //       index = this.filteredChatMembers.findIndex(
  //         member => (member.inviteStatus === 'Accepted') && ((member.receiverId  === receivedChatData.senderId) || (member.senderId === receivedChatData.senderId))
  //         // member => (member.inviteStatus === 'Accepted')
  //       );
  //       console.log("Inbox Screen");

  //     }
  //     console.log("index", index);
  //     console.log("Match Found!");

  //     // if (index) {
  //       const [item] = this.filteredChatMembers.splice(index, 1); // Splice removes the item from it's current index and retruns it
  //       this.filteredChatMembers.unshift(item); // Moves the Chat Item to the Top of the Array
  //       item.lastMessage = (receivedChatData.chatMessage || (this.isPdf(receivedChatData.attachmentUrl) ? 'PDF File' : 'Image')); // Insert the latest message sent in the item
  //       item.lastMessageTime = receivedChatData.createdDate; // Insert the latest lastMessageTime sent in the item
  //       item.formattedLastMessageTime = this.getFormattedLastMessageTime(receivedChatData.createdDate); // Insert the latest formattedLastMessageTime sent in the item

  //       console.log("this.channelId", this.channelId);
  //       // Update unreadCount only if the respective sender's chat is not opened by the receiver
  //       if (this.channelId !== receivedChatData.channelId) {
  //         item.unreadCount = receivedContactsData.unreadCount; // Insert the latest lastMessageTime sent in the item
  //       };

  //       console.log("item.lastMessage", item.lastMessage);
  //       console.log("item.lastMessageTime", item.lastMessageTime);
  //       console.log("formattedLastMessageTime", item.formattedLastMessageTime);

  //       // Set justMovedItemId to trigger the animation at the Receiver's Side
  //       this.justMovedItemId = item.channelId;

  //       // Reset justMovedItemId after the animation duration (e.g., 300ms)
  //       setTimeout(() => {
  //         this.justMovedItemId = null;
  //       }, 300);
  //     // }
  //   }

  //   console.log("Updated filteredChatMembers", this.filteredChatMembers);
  // };

  // New Method
  moveCurrentChatUpReceiver(chatData) {
    console.log("chatData", chatData);

    const { chatData: receivedChatData, contactsData: receivedContactsData } = chatData;
    console.log("this.filteredChatMembers", this.filteredChatMembers);
    console.log("this.selectedChatContactId", this.selectedChatContactId);

    const findIndex = () => {
      if (this.type === 'Project') {
        return this.filteredChatMembers.findIndex(
          member => member.projectId === receivedContactsData.projectId &&
            member.personIdHavingProject === receivedChatData.senderId
        );
      } else if (this.type === 'Inbox') {
        return this.filteredChatMembers.findIndex(
          member => member.inviteStatus === 'Accepted' &&
            (member.receiverId === receivedChatData.senderId ||
              member.senderId === receivedChatData.senderId)
        );
      }
      return -1;
    };

    const updateChatItem = (item) => {
      item.lastMessage = receivedChatData.chatMessage ||
        (this.isPdf(receivedChatData.attachmentUrl) ? 'PDF File' : 'Image');
      item.lastMessageTime = receivedChatData.createdDate;
      item.formattedLastMessageTime = this.getFormattedLastMessageTime(receivedChatData.createdDate);

      if (this.channelId !== receivedChatData.channelId) {
        item.unreadCount = receivedContactsData.unreadCount;
      }

      // if(!item.contactId){
      item.contactId = receivedContactsData._id;
      // }

      // if(!item.channelId){
      item.channelId = receivedContactsData.channelId;
      // }
      console.log("receivedContactsData", receivedContactsData);
      console.log("item.contactId", item.contactId);
      console.log("item.channelId", item.channelId);

      console.log("item.lastMessage", item.lastMessage);
      console.log("item.lastMessageTime", item.lastMessageTime);
      console.log("formattedLastMessageTime", item.formattedLastMessageTime);
    };

    let index = this.filteredChatMembers.findIndex(
      member => member.channelId === receivedChatData.channelId
    );

    if (index === -1) {
      index = findIndex();
    }

    if (index > -1) {
      const [item] = this.filteredChatMembers.splice(index, 1);
      this.filteredChatMembers.unshift(item);
      updateChatItem(item);

      this.justMovedItemId = item.channelId;
      setTimeout(() => {
        this.justMovedItemId = null;
      }, 300);
    }

    console.log("Updated filteredChatMembers", this.filteredChatMembers);
  }



  /* ---------------------------------------------------------------------------------------------------
 ** Method to Reset Chat Details Starts
 ** -------------------------------------------------------------------------------------------*/
  clearChatDetails() {
    this.channelId = "";
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Unread Count to Sender Starts
 ** -------------------------------------------------------------------------------------------*/

  notifySenderUnreadCount(notificationType) {
    const eventName = 'reply-notification';
    // const eventName = 'unreadCount-update-notification';
    const data = {
      notificationType: notificationType,
      timestamp: new Date(),
      channelId: this.channelId,
      personId: this.selectedChatPersonId,
      senderPersonId: this.myPersonId,
      receiverOnline: true
      // Add any other relevant data here
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.selectedChatPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);
    // old code
    // this.pusherService.notifyUser("private-crecientech-chat66a36162cd67818f50506029", eventName, data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Update Sender Unread Count Starts
 ** -------------------------------------------------------------------------------------------*/
  updateSenderUnreadCount(data) {
    console.log('data', data);

    console.log('data.channelId', data.channelId);
    console.log('this.channelId:', this.channelId);

    this.receiverOnline = ((data.receiverOnline === true) && (data.channelId === this.channelId)) ? true : false;
    // this.receiverOnline = data.receiverOnline ? true : false;

    console.log('data.receiverOnline', data.receiverOnline);
    console.log('this.receiverOnline', this.receiverOnline);

    const index = this.filteredChatMembers.findIndex(
      member => member.channelId === data.channelId
    );

    console.log("index", index);

    if (index !== -1) { // Ensure the index is valid
      const item = this.filteredChatMembers[index]; // Retrieve the item at the found index
      item.unreadCount[data.personId] = 0; // Set unread count to 0 for the specified personId
      console.log("item.unreadCount", item.unreadCount);
    } else {
      console.log('No member found with the given channelId');
    };

    this.selectedChatUnreadCount[data.personId] = 0;
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Unread Count to Sender Starts
 ** -------------------------------------------------------------------------------------------*/

  notifyReceiverChatOpened() {
    const eventName = 'reply-notification';
    // const eventName = 'unreadCount-update-notification';
    const data = {
      notificationType: 'receiverChatOpened',
      timestamp: new Date(),
      channelId: this.channelId,
      personId: this.selectedChatPersonId,
      receiverOnline: false
      // Add any other relevant data here
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.selectedChatPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);
    // old code
    // this.pusherService.notifyUser("private-crecientech-chat66a36162cd67818f50506029", eventName, data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  updateReceiverOnline(data) {
    console.log('data', data);

    this.receiverOnline = data.receiverOnline ? true : false;

    console.log('data.receiverOnline', data.receiverOnline);
    console.log('this.receiverOnline', this.receiverOnline);

  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Show Video Call Screen to Caller Starts
** -------------------------------------------------------------------------------------------*/
  showVideoCaller() {
    // Prepare the data to be sent
    const videoCallerData = {
      callData: this.selectedChatItem,
      roleType: this.roleType,
      receiverPersonChannelId: this.selectedChatPersonChannelId
    };

    // Send the data via shared service
    this.CommonService.sendVideoCallerData(videoCallerData);
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Check Role Type Starts
** -------------------------------------------------------------------------------------------*/
  checkCallerRoleType(roleCode) {
    let roleType;
    if (roleCode === '100') {
      roleType = 'Fixer';
    } else if (roleCode === '101') {
      roleType = 'Founder';
    } else if (roleCode === '103') {
      roleType = "Funder"
    } else {
      roleType = "Guest"
    } return roleType;
  }

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Video Call to Receiver Starts
 ** -------------------------------------------------------------------------------------------*/

  notifyReceiverVideoCall() {
    const eventName = 'reply-notification';
    let callData = {
      name: this.appStateService.globalData.name,
      imageUrl: this.appStateService.globalData.imageURL,
    };

    let callerRoleType = this.checkCallerRoleType(this.appStateService.globalData.roleCode);

    const data = {
      notificationType: "incomingVideoCall",
      timestamp: new Date(),
      channelId: this.channelId,
      personId: this.selectedChatPersonId,
      callData: callData,
      roleType: callerRoleType,
      senderPersonId: this.myPersonId,
      senderPersonChannelId: this.appStateService.globalData.personChannelId
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.selectedChatPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Display Video Call Declined by Receiver Alert to Sender Starts
  ** -------------------------------------------------------------------------------------------*/
  async callDeclinedAlert() {
    let name = this.selectedChatItem.name || this.selectedChatItem.personData.name
    const confirm = await this.alertController.create({
      header: 'Call Declined',
      message: `${name} declined the call`,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'cancel',
        }
      ]
    });
    await confirm.present();
  };




}
