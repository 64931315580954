import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import Swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { AlertController, LoadingController } from '@ionic/angular';
const URL = 'api/attachment/uploadDirectToS3Profile';

@Component({
  selector: 'attachment-web-profile',
  templateUrl: './attachment-web-profile.component.html',
  styleUrls: ['./attachment-web-profile.component.scss'],
})
export class AttachmentWebProfileComponent implements OnInit {

  avatar$;
  imageDataAssign = "assets/icon/camera.svg";
  showUserDetailsUI = false;
  showProfileUI = false;
  imageDataAssignWeb = "assets/icon/profile/Add New.svg"
  // input and output
  @Output() imageUrl: EventEmitter<any> = new EventEmitter<any>();
  @Input('imageData') imageData;
  @Input() uImagePathParam: any;
  @Input() textInput: any;

  @Input() set page(value) {
    console.log(value)
    if (value == "profile") {
      this.showUserDetailsUI = false
      this.showProfileUI = true
    }
    if (value == "userDetails") {
      this.showUserDetailsUI = true
      this.showProfileUI = false
    }
  }

  // fileupload required data

  public uUploader: FileUploader = new FileUploader({
    url: URL,
    additionalParameter: { type: 'inventFund' },
    headers: [
      {
        name: 'Accept',
        value: 'application/json',
      },
    ],
    allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
    maxFileSize: 50 * 1024 * 1024,
  });

  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  /**
   *
   * @param appState get variables and methods from app state
   */

  constructor(
    public appStateService: AppStateService,
    public loadingController: LoadingController,
    public alertController: AlertController
  ) {
    console.log("................" + appStateService.globalData.imageURL);

  }

  // ngOninit

  ngOnInit() {
    // set folder path for an attachment URL

    this.avatar$ = this.appStateService.getAvatar()
    this.uUploader.onProgressItem = (progress: any) => {
      console.log("................" + progress['progress']);
    };


    this.uUploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
      const json = JSON.stringify(filter);
      console.log('local after : ' + JSON.stringify(item));
      console.log('filter after : ' + JSON.stringify(filter));
      const name = item.name;
      const lastDot = name.lastIndexOf('.');

      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      console.log("ext :" + ext)
      let message = '';

      switch (filter.name) {
        case 'fileSize':
          message = 'Warning ! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) +
            ', this exceeds the maximum allowed size of ' + this.formatBytes(50 * 1024 * 1024);
          var obj = {
            header: "Error!",
            subHeader: message,
            css: "auth-alert"
          }
          this.appStateService.genericAlert(obj);
          break;

        case 'mimeType': message = "." + ext + " File not allowed! Only images are allowed";
          var obj = {
            header: "Error!",
            subHeader: message,
            css: "auth-alert"
          }
          this.appStateService.genericAlert(obj);
          break;
        default:
          message = 'Error trying to upload file   ' + item.name;
          var obj = {
            header: "Error!",
            subHeader: message,
            css: "auth-alert"
          }
          this.appStateService.genericAlert(obj);
          break;
      }

      this.uUploader.onBuildItemForm = (item, form) => {
        form.append('TYPE', this.uImagePathParam.type);
      };

      // get response oncomplete of attachment


    }
    this.uUploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      var data: any = response;
      var resCode =JSON.parse(data).code
      if (resCode == 400) {
        console.log("show alert")
       this.showAlertMess("Please Upload minimum picture size of 1184x915")
      } else {
        var emitImage = JSON.parse(data).data;
        console.log((emitImage))
        console.log(JSON.stringify(emitImage))
        this.imageDataAssign = emitImage
        // this.imageDataAssignWeb = emitImage
        this.appStateService.globalData.imageURL = emitImage
        this.imageUrl.emit(emitImage);
      }


      // this.showProgress = false;
    };

    this.uUploader.onBuildItemForm = (item, form) => {
      form.append('TYPE', this.uImagePathParam.type);
    };
  }


    async showAlertMess(mess) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        // header: 'Alert',
        subHeader: mess,
        // message: 'This is an alert message.',
        buttons: ['OK']
      });
  
      await alert.present();
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // on file select complete upload in to s3 bucket

  onFileSelected(event) {
    console.log(".................")
    this.uUploader.uploadAll();
  }


}
