import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import { AppStateService } from 'src/app/services/app-state.service';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';
import { toastMessages } from 'src/utilities/toastMessage';
import { ThemeService } from './theme.service';
import { ViewChild } from '@angular/core';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
//from kavaho
import { HostBinding, Injector, Inject, HostListener } from '@angular/core';
declare var $: any;
import { UserIdleService } from 'angular-user-idle';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
// import { AppState, WindowSize } from './app.service';
import { DOCUMENT } from '@angular/common';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CommonServiveService } from './common-servive.service';
import { PusherService } from './shared/messages/services/pusher.service';
import { NotificationServiceService } from './services/notification-service.service';
import * as moment from 'moment';
import { VideoCallerComponent } from './shared/video-caller/video-caller.component';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('videoCaller') videoCaller: VideoCallerComponent;

  themeVal: any;
  public editorOptions: GanttEditorOptions;
  public data1: any;
  @ViewChild(GanttEditorComponent, { static: true })
  editor: GanttEditorComponent;
  personTheme: any;

  //From Kavaho
  // -------------------------------------------------------------------------
  // Local variable declarations
  // -------------------------------------------------------------------------
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  timerIdle: 10;
  timerTimeout: 10;
  timerPing: 5;

  // private values: Array<WindowSize> = [];
  private anyErrors: boolean;
  private finished: boolean;
  private subscription;

  innerWidth;
  retrievedPersonDetails: any;

  // Person ChannelId realated variables
  private channel: any; // Keep a reference to the channel
  private processedNotifications = new Set<string>();
  private callbackRegistered: boolean = false;
  private channelIdRetrieved = false; // Flag to track if retrieveChannelId has been called

  // List of valid notification types
  validNotificationTypes = [
    'projectMessage',
    'inboxMessage',
    'connectionRequest',
    'projectInvite',
    'ndaInvite',
    'agreementInvite',
    'chatUpdateProject',
    'chatUpdateInbox',
    'unreadUpdate',
    'receiverChatOpened',
    'meetingFailedToConnect'
  ];
  notifyRetrieveChannelId: any;
  videoCallScreenType: ''; // Either caller or receiver
  private videoCallerDataSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private theme: ThemeService,
    public popoverController: PopoverController,
    private inventFundsApiService: InventfundsApiService,
    private appStateService: AppStateService,
    //from Kavaho
    private userIdle: UserIdleService,
    private router: Router,
    // private appState: AppState,
    private idle: Idle,
    private keepalive: Keepalive,
    @Inject(DOCUMENT) private document: Document,
    public CommonService: CommonServiveService,
    private pusherService: PusherService,
    private notificationService: NotificationServiceService,
    public alertController: AlertController,
  ) {
    this.getTheme();
    // this.setTheme();
    this.getPersonTheme();

    //From Kavaho
    // ng2-idle configurations
    // sets an idle timeout of 1800 seconds or 30 min.
    // idle.setIdle(180000);
    // sets an idle timeout of 900 seconds or 15 min.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log("this.idleState onIdleEnd:" + this.idleState);
    });
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      // console.log("this.idleState onTimeout:" + this.idleState);
      this.logOut();
      this.timedOut = true;
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      // console.log("this.idleState onIdleStart:" + this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log("this.idleState onTimeoutWarning:" + this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  //From Kavaho/// Keyboard Events
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:keydown', ['$event'])

  /// Mouse Events
  @HostListener('document:click', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:DOMMouseScroll', ['$event'])
  @HostListener('document:mousewheel', ['$event'])
  @HostListener('document:mousedown', ['$event'])

  /// Touch Events
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchmove', ['$event'])

  /// Common Events
  @HostListener('document:scroll', ['$event'])
  @HostListener('document:focus', ['$event'])

  ngOnInit(): void {

  };

  ngAfterViewInit(): void {
    // Subscribe to the channel ID retrieval notification
    this.CommonService.channelIdRetrieved$.pipe(take(1)).subscribe(() => {
      if (!this.channelIdRetrieved) {
        this.retrieveChannelId();
        this.channelIdRetrieved = true;
      }
    });

    // To Subscribe for Display in Video Call Screen when a Caller initiates a Video Call
    this.videoCallerDataSubscription = this.CommonService.videoCallerData$.subscribe(data => {
      this.showVideoCallerForCaller(data);
    });

    //For development purpose
    // setTimeout(() => {
    console.log("this.appStateService.globalData.personChannelId", this.appStateService.globalData.personChannelId);

    // if (localStorage.getItem('encryptedChannelId') != null && !this.notifyRetrieveChannelId) {
    if (localStorage.getItem('encryptedChannelId') != null) {
      this.CommonService.notifyRetrieveChannelId();
      // this.notifyRetrieveChannelId = true;
    }
    // }, 3000);

    $(document).off('click', '[href="#"]').on('click', '[href="#"]', (e) => e.preventDefault());
  };


  getTheme() {
    var params = {
      collectionName: 'person',
      _id: this.appStateService.decryptedDataValue('personId'),
    };
    this.inventFundsApiService
      .retrieveMongoDBOne(params)
      .then((res) => {
        if (res.response.length) {
          this.themeVal = res.response[0].theme;
          this.theme.activeTheme(this.themeVal);
        } else {
          this.theme.activeTheme('default');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getPersonTheme() {
    var params = {
      collectionName: 'person',
      _id: this.appStateService.decryptedDataValue('personId'),
    };
    this.inventFundsApiService
      .retrieveMongoDBOne(params)
      .then((res) => {
        this.retrievedPersonDetails = res.response;
        // console.log("Retrieved Person Info", this.retrievedPersonDetails);

        this.personTheme = this.retrievedPersonDetails[0]?.theme;
        // console.log("Person Theme Value",this.retrievedPersonDetails[0]?.theme );
        console.log('Person Theme Value', this.personTheme);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onActivate(event, outlet) {
    outlet.scrollTop = 0;
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  // -------------------------------------------------------------------------
  // Front end other methods called on user inputs
  // -------------------------------------------------------------------------
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  logOut() {
    // this.appState.currentScreen = 'LANDING';
    localStorage.clear();
    // this.appState.resetData();
    this.router.navigate(['./landingpage']);
    // this.restart();
    // this.userIdle.startWatching();

    this.reset();
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Get personChannelId from DB Starts
   ** -------------------------------------------------------------------------------------------*/
  // retrieveChannelId(): Promise<void> {
  //   console.log('retrieveChannelId Called in service file');
  //   console.log("this.appStateService.globalData.personId", this.appStateService.globalData.personId);


  //   var params = {
  //     collectionName: 'personChannel',
  //     queryStr: {
  //       personId: this.appStateService.globalData.personId,
  //     },
  //     fetchSingle: true, // Toggle this to true when you want to fetch only 1 record using retrieveMongoDBAll
  //   };

  //   return this.inventFundsApiService
  //     .retrieveMongoDBAll(params)
  //     .then((res) => {
  //       console.log('res.response', res.response);
  //       if (res.response.length > 0) {
  //         this.appStateService.globalData.personChannelId = res.response[0]._id;
  //         console.log(
  //           'this.appStateService.globalData.personChannelId',
  //           this.appStateService.globalData.personChannelId
  //         );
  //         this.subscribeToGeneralChannel();
  //       } else {
  //         return this.createPersonChannelId(); // Chain createPersonChannelId
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error(err);
  //       var obj = toastMessages.retrieveErrMess;
  //       this.appStateService.genericToast(obj);
  //       // Return a rejected promise
  //       return Promise.reject(err);
  //     });
  // }

  retrieveChannelId(): Promise<void> {
    console.log('retrieveChannelId Called in service file');
    console.log("this.appStateService.globalData.personId", this.appStateService.globalData.personId);

    var params = {
      collectionName: 'personChannel',
      queryStr: {
        personId: this.appStateService.globalData.personId,
      },
      fetchSingle: true,
    };

    return this.inventFundsApiService
      .retrieveMongoDBAll(params)
      .then((res) => {
        console.log('res.response', res.response);
        if (res.response.length > 0) {
          this.appStateService.globalData.personChannelId = res.response[0]._id;
          console.log(
            'this.appStateService.globalData.personChannelId',
            this.appStateService.globalData.personChannelId
          );

          // Encrypt the personChannelId before storing in localStorage
          const encryptedChannelId = CryptoJS.AES.encrypt(
            this.appStateService.globalData.personChannelId,
            'your-secret-key'
          ).toString();

          localStorage.setItem('encryptedChannelId', encryptedChannelId);

          this.subscribeToGeneralChannel();
        } else {
          return this.createPersonChannelId();
        }
      })
      .catch((err: any) => {
        console.error(err);
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
        return Promise.reject(err);
      });
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to create personChannelId and Save to DB Starts
   ** -------------------------------------------------------------------------------------------*/
  createPersonChannelId(): Promise<void> {
    console.log('createPersonChannelId Called');

    var params = {
      collectionName: 'personChannel',
      updateData: {
        personId: this.appStateService.globalData.personId,
        name: this.appStateService.globalData.name,
        roleCode: this.appStateService.globalData.roleCode,
        roleType: this.appStateService.globalData.partyCode,
        deviceToken: '',
        status: 'active',
        preferences: '',
        createdAt: moment().format(),
        updatedAt: moment().format(),
      },
    };

    return this.inventFundsApiService
      .createMongodbData(params)
      .then((res) => {
        console.log('res.response', res.response);
        if (res.response) {
          this.appStateService.globalData.personChannelId =
            res.response.insertedId;
          console.log(
            'this.appStateService.globalData.personChannelId',
            this.appStateService.globalData.personChannelId
          );
          this.subscribeToGeneralChannel();
        }
      })
      .catch((err: any) => {
        console.error(err);
        var obj = toastMessages.saveErrMsg;
        this.appStateService.genericToast(obj);
        // Return a rejected promise
        return Promise.reject(err);
      });
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Subscribe to personChannelId Starts
   ** -------------------------------------------------------------------------------------------*/
  subscribeToGeneralChannel() {
    const personChannelId = this.appStateService.globalData.personChannelId;
    const generalChannelName = `private-crecientech-chat${personChannelId}`;

    console.log(`Subscribing to channel: ${generalChannelName}`);

    // Ensure we only subscribe and bind once
    if (!this.channel) {
      this.channel = this.pusherService.pusher.subscribe(generalChannelName);
      console.log(`Channel subscribed: ${generalChannelName}`);

      // Ensure we only bind the event once
      this.channel.bind(
        'client-reply-notification',
        this.handleReplyNotification.bind(this)
      );
      console.log(`Event bound to ${generalChannelName}`);
    } else {
      console.log(`Already subscribed to channel: ${generalChannelName}`);
    }

    // Optionally log subscribed channels for debugging
    setInterval(() => {
      // const subscribedChannels = Object.keys(
      //   this.pusherService.pusher.channels.channels
      // );
      // console.log('Currently subscribed channels:', subscribedChannels);
    }, 500);

    // Optional logging for debugging
    // this.pusherService.pusher.connection.bind('state_change', (state) => {
    //   console.log('Pusher connection state changed:', state);
    // });
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Handle Notifications Starts
   ** -------------------------------------------------------------------------------------------*/
  handleReplyNotification(data: any) {
    const notificationId = data.timestamp || data.id; // Use a unique identifier for the notification

    if (this.processedNotifications.has(notificationId)) {
      console.log('Notification already processed:', notificationId);
      return;
    }

    console.log('Handling reply notification:', data);
    // if (data.notificationType === 'projectMessage' || data.notificationType === 'inboxMessage' || data.notificationType === 'connectionRequest') {
    //   this.notificationService.sendNotification(data);
    // };

    // Check if the notification type is valid
    if (this.validNotificationTypes.includes(data.notificationType)) {
      this.notificationService.sendNotification(data);
    }

    if (data.notificationType === 'incomingVideoCall') {
      this.showVideoCallerReceiver(data); // To Open Display the Incoming Call from Receiver's Side
    }

    if (data.notificationType === 'videoCallEnd') {
      this.endCallByCaller(); // To close the Video Caller Screen from Receiver's Side
    }

    if (data.notificationType === 'videoCallDeclined') {
      this.videoCaller.endCallByCaller(); // Notify messages.component.ts to close the Video Caller Screen from Caller's Side
      this.CommonService.notifyCallDecline(); // Notify the messages component
    }

    if (data.notificationType === 'videoCallAccepted') {
      console.log("data.notificationType:", data.notificationType);

      this.videoCaller.startVideoCall(); // Start the Video Call from Caller's Side
    }

    if (data.notificationType === 'meetingDetails') {
      console.log("data.notificationType:", data.notificationType);
      console.log("data:", data);
      // this.videoCaller.getSignatureReceiver(data.meetingDetails); // Start the Video Call from Receiver's Side
      this.videoCaller.connecting = false;
      this.videoCaller.acceptCallByReceiver();
      this.videoCaller.joinMeetingReceiver(data.meetingDetails, data.meetingDetails.signature); // Start the Video Call from Receiver's Side
    }

    if (data.notificationType === 'meetingFailedToConnect') {
      this.endCallByCaller();
      this.displayFailedToConnectAlert();
    }

    // Mark the notification as processed
    this.processedNotifications.add(notificationId);
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Clean up the event listener in ngOnDestroy Starts
   ** -------------------------------------------------------------------------------------------*/
  ngOnDestroy() {
    if (this.channel && this.channel.isBound) {
      this.channel.unbind('client-reply-notification');
      this.channel.unsubscribe();
      this.channel.isBound = false;
    }

    if (this.videoCallerDataSubscription) {
      this.videoCallerDataSubscription.unsubscribe();
    };

  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Show Incoming Call to Receiver Starts
  ** -------------------------------------------------------------------------------------------*/
  showVideoCallerReceiver(data) {
    console.log("showVideoCallerReceiver() called");
    this.videoCaller.videoCallScreenType = 'receiver'; // or 'receiver' depending on your logic
    this.videoCaller.callData = data.callData;
    this.videoCaller.roleType = data.roleType;
    this.videoCaller.senderPersonChannelId = data.senderPersonChannelId;
    this.videoCaller.toggleCallerDialogReceiver();
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Show Outgoing Call to Caller Starts
  ** -------------------------------------------------------------------------------------------*/
  showVideoCallerForCaller(data: any) {
    console.log("showVideoCallerForCaller() called");

    this.videoCaller.videoCallScreenType = 'caller'; // or 'receiver' depending on your logic
    // Assign values from the received data
    this.videoCaller.callData = data.callData;
    this.videoCaller.roleType = data.roleType;
    this.videoCaller.receiverPersonChannelId = data.receiverPersonChannelId;

    // Call the method to toggle the dialog
    this.videoCaller.toggleCallerDialogCaller();
  }

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Video Caller to Receiver Starts
   ** -------------------------------------------------------------------------------------------*/
  endCallByCaller() {
    this.videoCaller.endCallByCaller();
    this.videoCallScreenType = '';
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Display Failed to Connect to Meeting to Receiver Starts
   ** -------------------------------------------------------------------------------------------*/
  async displayFailedToConnectAlert(){
    const confirm = await this.alertController.create({
      header: 'Failed to Connect',
      message: `Failed to connect to the meeting, Please try again later`,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'cancel',
        }
      ]
    });
    await confirm.present();
  }
}
