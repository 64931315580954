import { HostListener, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from, Subject } from 'rxjs';
import { InventfundsApiService } from './services/inventfunds-api.service';
import { toastMessages } from 'src/utilities/toastMessage';
import { AppStateService } from './services/app-state.service';
import * as moment from 'moment';
import { PusherService } from './shared/messages/services/pusher.service';

@Injectable({
  providedIn: 'root'
})
export class CommonServiveService {
  autofocus: boolean;

  public Selected = new BehaviorSubject("")
  public SelectedProj = new BehaviorSubject("")
  public projectUpdated: Subject<void> = new Subject<void>();
  public teamsUpdated: Subject<void> = new Subject<void>();
  public teamMembersUpdated: Subject<void> = new Subject<void>();
  public projectHeaderUpdated: Subject<void> = new Subject<void>();
  public resultUpdated: Subject<void> = new Subject<void>();
  public projectIdDetails: Subject<any> = new Subject<any>();
  private updateFollowedPersonsSource = new BehaviorSubject<boolean>(false);
  updateFollowedPersons$ = this.updateFollowedPersonsSource.asObservable();
  public investmentComplete: Subject<void> = new Subject<void>();

  public goToUserDashboard$ = new Subject<void>();
  recievedSearchParams: any;
  personData: any;
  // matchMeParams: BehaviorSubject<any>;
  private matchedResultsArray: any[] = [];
  // roleMap: { [key: string]: string } = {
  //   '200': 'Project Manager',
  //   '201': 'Team Lead',
  //   '202': 'Designer',
  //   '203': 'Developer',
  // };

  roleMap: { [key: string]: string } = {}; // Define roleMap

  private activeTabSubject = new BehaviorSubject<string>('dashboard');
  activeTab$ = this.activeTabSubject.asObservable();



  // Create a new Subject
  private savePersonProjectsCompletedSource = new Subject<void>();

  // Expose the Subject as an Observable
  savePersonProjectsCompleted$ = this.savePersonProjectsCompletedSource.asObservable();
  recievedResultsParams: any;
  recievedProjectParams: any;
  // matchMeParams: any;

  // Method to emit the event
  emitSavePersonProjectsCompleted() {
    this.savePersonProjectsCompletedSource.next();
  }

  private initializeComponentSubject = new Subject<void>();
  initializeComponent$ = this.initializeComponentSubject.asObservable();


  triggerComponentInitialization(): void {
    this.initializeComponentSubject.next();
  }


  constructor(
    private inventFundsApiService: InventfundsApiService,
    private appStateService: AppStateService,
    private pusherService: PusherService
  ) {

  };

  private channelIdRetrieved = new Subject<void>();

  // Observable that app.component can subscribe to
  channelIdRetrieved$ = this.channelIdRetrieved.asObservable();

  // Method to notify app.component to retrieve the channel ID
  notifyRetrieveChannelId() {
    if (!this.appStateService.globalData.personChannelId) {
      this.channelIdRetrieved.next();
    }
  }


  public emitProjectUpdated(): void {
    this.projectUpdated.next();
  }

  public emitUpdateFunderWidgets(): void {
    this.investmentComplete.next();
  }

  public emitTeamsUpdated(): void {
    this.teamsUpdated.next();
  }

  public emitTeamMembersUpdated(): void {
    this.teamMembersUpdated.next();
  }

  // To Updated Person Followers dropdown in My Team Members Widget
  triggerUpdateFollowedPersons() {
    this.updateFollowedPersonsSource.next(true);
  }

  public emitProjectHeaderUpdated(): void {
    this.projectHeaderUpdated.next();
    // console.log("emitProjectHeaderUpdated Called in service file");

  }


  public emitMatchMeResultsUpdated(): void {
    this.resultUpdated.next();

    // console.log("emitMatchMeResultsUpdated Called");

  }


  public emitMatchMeResult(searchParams: any): void {
    searchParams = searchParams;
    this.recievedSearchParams = searchParams;
    // console.log("Recieved Search Params in Service File", this.recievedSearchParams);

  }


  public emitMatchMeWidget(resultsParams: any): void {
    resultsParams = resultsParams;
    this.recievedResultsParams = resultsParams;
  }

  public emitProjectDetails(projectParams: any): void {

    projectParams = projectParams;
    this.recievedProjectParams = projectParams;
    this.projectIdDetails.next(projectParams);
    localStorage.setItem('projectId', JSON.stringify(this.recievedProjectParams));
  }


  setMatchedResultsArray(results: any[]) {
    this.matchedResultsArray = results[0];
    localStorage.setItem('matchedResultsKey', JSON.stringify(results[0])); // Save to Local Storage
    // console.log("Matched Results Array", this.matchedResultsArray);

  }

  getMatchedResultsArray() {
    return this.matchedResultsArray;
  }

  getProjectRoleList() {
    const params = {
      collectionName: 'projectRole',
    };

    this.inventFundsApiService.retrieveMongoDBAll(params).then((res: any) => {

      const roleArray = res.response;

      // console.log('roleArray', roleArray);

      if (roleArray && roleArray.length > 0) {

        // Generate roleMap dynamically from roleArray
        this.roleMap = {};
        for (const role of roleArray) {
          this.roleMap[role.roleCode] = role.roleName;
        }

      }


      // console.log('roleMap', this.roleMap);

    })
      .catch((err: any) => {
        console.error(err);
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });

  };

  comingSoon() {
    var obj = toastMessages.comingSoonDefaultMsg;
    this.appStateService.genericToast(obj);
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.autofocus = event.target.innerWidth > 991;
  }

  setActiveTab(tab: string): void {
    this.activeTabSubject.next(tab);
  };

  // To Open the Video Call Window at Caller's Side
  private videoCallerDataSubject = new Subject<any>();

  videoCallerData$ = this.videoCallerDataSubject.asObservable();

  sendVideoCallerData(data: any) {
    this.videoCallerDataSubject.next(data);
  }

  // To Display an Alert to Caller when Receiver Declines the Video Call
  private videoCallDeclineSubject = new Subject<void>();

  videoCallDecline$ = this.videoCallDeclineSubject.asObservable();

  notifyCallDecline() {
    this.videoCallDeclineSubject.next();
  }


}
